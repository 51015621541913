import { EButton } from "../../../Enum";
import { ButtonsDisabled } from "../../../types/types";

type DialogInfo = {
    endpoint: string;
    title: string;
    subtext: string;
}

export type State = {
    buttonClicked: EButton;
    hideDialog: boolean;
    isLoading: boolean;
    disabledButtons: ButtonsDisabled;
    dialogInfo: DialogInfo;
    data: {[key: string]: any}
};

export function defaultState(): State {
    return {
        buttonClicked: EButton.NONE,
        disabledButtons: {},
        hideDialog: true,
        isLoading: false,
        dialogInfo: {
            endpoint: '',
            title: '',
            subtext: ''
        },
        data: {}
    }
}

export type LoadButtonsInitial = {
    type: 'loadButtons';
    disabledButtons: ButtonsDisabled;
    hideDialog: boolean;
};

export type HideDialog = {
    type: 'hideDialog';
    hideDialog: boolean;
    isLoading: boolean;
};

export type DisplayLoading = {
    type: 'displayLoading';
    isLoading: boolean;
};

export type CreateDatabaseDump = {
    type: 'createDatabaseDump';
    buttonClicked: EButton;
    hideDialog: boolean;
    isLoading: boolean;
    dialogInfo: DialogInfo;
};

export type ForceDatabaseDump = {
    type: 'forceDump';
    buttonClicked: EButton;
    hideDialog: boolean;
    isLoading: boolean;
    dialogInfo: DialogInfo;
}

export type CleanupCypressData = {
    type: 'cleanupCypressData';
    buttonClicked: EButton;
    hideDialog: boolean;
    isLoading: boolean;
    dialogInfo: DialogInfo;
};

export type RunCypressTests = {
    type: 'runCypressTests';
    buttonClicked: EButton;
    hideDialog: boolean;
    isLoading: boolean;
    dialogInfo: DialogInfo;
};

export type RerunLastBuild = {
    type: 'rerunLastBuild';
    buttonClicked: EButton;
    hideDialog: boolean;
    isLoading: boolean;
    dialogInfo: DialogInfo;
    data: {
        buildId: number;
    }
};

export type Action = LoadButtonsInitial | DisplayLoading | HideDialog | CreateDatabaseDump | ForceDatabaseDump | CleanupCypressData | RunCypressTests | RerunLastBuild;

export function dataStateReducer(state: State, action: Action): State {
    switch (action.type) {
        case 'loadButtons': {
            return {
                ...state,
                disabledButtons: action.disabledButtons,
                hideDialog: action.hideDialog
            }
        }
        case 'displayLoading': {
            return {
                ...state,
                isLoading: action.isLoading
            }
        }
        case 'hideDialog': {
            return {
                ...state,
                hideDialog: action.hideDialog,
                isLoading: action.isLoading
            }
        }
        case 'createDatabaseDump': {
            return {
                ...state,
                buttonClicked: action.buttonClicked,
                hideDialog: action.hideDialog,
                isLoading: action.isLoading,
                dialogInfo: action.dialogInfo
            }
        }
        case 'forceDump': {
            return {
                ...state,
                buttonClicked: action.buttonClicked,
                hideDialog: action.hideDialog,
                isLoading: action.isLoading,
                dialogInfo: action.dialogInfo
            }
        }
        case 'cleanupCypressData': {
            return {
                ...state,
                buttonClicked: action.buttonClicked,
                hideDialog: action.hideDialog,
                isLoading: action.isLoading,
                dialogInfo: action.dialogInfo
            }
        }
        case 'runCypressTests': {
            return {
                ...state,
                buttonClicked: action.buttonClicked,
                hideDialog: action.hideDialog,
                isLoading: action.isLoading,
                dialogInfo: action.dialogInfo
            }
        }
        case 'rerunLastBuild': {
            return {
                ...state,
                buttonClicked: action.buttonClicked,
                hideDialog: action.hideDialog,
                isLoading: action.isLoading,
                dialogInfo: action.dialogInfo,
                data: action.data
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${(action as any).type}`);
        }
    }
}

export type Dispatch = (action: Action) => void;
