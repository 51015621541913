import * as React from 'react';
import * as _ from 'lodash';
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import { EButton } from '../../../../Enum';
import PFSService from '../../../../services/PFSService';
import ProjectNameChoiceGroup from '../ProjectNameChoiceGroup/ProjectNameChoiceGroup';
import MilestoneChoiceGroup from '../MilestoneChoiceGroup/MilestoneChoiceGroup';
import IntegrationsCheckbox from '../IntegrationsCheckbox/IntegrationsCheckbox';
import { CustomerSelected } from '../../../../types/types';

type Properties = {
    buttonClicked: EButton;
    isHidden: boolean;
    dialogInfo: {
        endpoint: string;
        title: string;
        subtext: string;
    };
    data: {[key: string]: any};
    onDismiss: () => void;
    onShow: () => void;
    onLoadLogsPanel: () => void;
    onProjectNameSelected: (projectName: string) => void;
};

const ReleaseDialog = (props: Properties) => {
    const [data, setData] = React.useState<{[key: string]: any}>();

    React.useEffect(() => {
        if (props.data.customersSelected) {
            setData({ ...data, customersSelected: props.data.customersSelected });
        }
    }, [props.data.customersSelected]);

    const onConfirmClick = () => {
        if (data?.customerAdded) {
            props.onProjectNameSelected(data.customerAdded);

        } else {
            const handlePostToEndpoint = async () => {
                const result = await PFSService.postToEndpoint(props.dialogInfo.endpoint, data);
            }
            handlePostToEndpoint();
        }

        props.onDismiss();
        props.onLoadLogsPanel();
    };

    const getDialogContent = () => {
        if (props.data.projectNames && props.buttonClicked == EButton.ADD_CUSTOMER_MILESTONE) {
            return (
                <ProjectNameChoiceGroup
                    onDismiss={props.onDismiss}
                    onProjectNameSelected={(projectName: string) => setData({ customerAdded: projectName })}
                    projectNames={props.data.projectNames}
                />
            );
        }

        if (props.data.milestones && props.buttonClicked == EButton.ADD_CUSTOMER_MILESTONE) {
            return (
                <MilestoneChoiceGroup
                    onDismiss={props.onDismiss}
                    onMilestoneIdSelected={(milestoneId: number) => setData({ milestoneIdSelected: milestoneId })}
                    milestones={props.data.milestones}
                />
            );
        }

        if (props.data.projectNames && [EButton.DEPLOY_BETA, EButton.DEPLOY_RC, EButton.BUILD_PRODUCTION].includes(props.buttonClicked)) {
            return (
                <IntegrationsCheckbox
                    projectNames={props.data.projectNames}
                    customersSelected={props.data.customersSelected ?? []}
                    onCustomerSelected={(customersSelected: CustomerSelected[]) => setData({ ...data, customersSelected })}
                />
            );
        }

        return <></>;
    };

    const getDialogFooterContent = () => {
        return (
            <DialogFooter>
                <PrimaryButton
                    onClick={onConfirmClick}
                    text="OK" />
                <DefaultButton
                    onClick={props.onDismiss}
                    text="Cancel" />
            </DialogFooter>
        );
    };

    return (
        <Dialog
            hidden={props.isHidden}
            onDismiss={props.onDismiss}
            dialogContentProps={{
                type: DialogType.normal,
                title: props.dialogInfo.title,
                closeButtonAriaLabel: 'Close',
                subText: props.dialogInfo.subtext,
            }}
        >
            { getDialogContent() }
            { getDialogFooterContent() }
        </Dialog>
    );
}


export default ReleaseDialog;