import PFSService from "../services/PFSService";
import { PFSProcessesService, Process, Step, processStepMap } from "./PFSProcesses.contract";

export class PFSProcessesServiceImpl implements PFSProcessesService {
    private allProcesses = [
        {
            id: 'hotfix',
            name: 'Apply an Emergency Hotfix'
        }
    ];

    private allSteps: Step[] = [
        {
            id: 'hotfix-selectProjectName',
            contentRendererKey: 'hotfix-selectProjectName',
            nextSteps: [{
                nextStepId: 'hotfix-createNewBranch',
                onSelected: async (projectName: string) => { return { customer: projectName } },
                label: ''
            }],
            buttonAlignment: 'horizontal'
        },
        {
            id: 'hotfix-createNewBranch',
            content: 'Create new branch or work on existing branch?',
            nextSteps: [{
                nextStepId: 'hotfix-rcOrProd',
                onSelected: async () => { return {} },
                label: 'Create New'
            }, {
                nextStepId: 'hotfix-listExistingBranches',
                onSelected: async () => { return {} },
                label: 'Work on existing'
            }],
            buttonAlignment: 'horizontal'
        },
        {
            id: 'hotfix-rcOrProd',
            content: 'Hotfix to RC or Production?',
            nextSteps: [{
                nextStepId: 'hotfix-testShowcase',
                onSelected: async (projectName: string) => { return await this.createHotfixShowcase(projectName, 'RC'); },
                label: 'RC'
            }, {
                nextStepId: 'hotfix-testShowcase',
                onSelected: async (projectName: string) => { return await this.createHotfixShowcase(projectName, 'Production'); },
                label: 'Production'
            }],
            buttonAlignment: 'horizontal'
        },
        {
            id: 'hotfix-listExistingBranches',
            contentRendererKey: 'hotfix-listExistingBranches',
            nextSteps: [{
                nextStepId: 'hotfix-testShowcase',
                onSelected: async () => { return {} },
                label: ''
            }],
            buttonAlignment: 'vertical'
        },
        {
            id: 'hotfix-testShowcase',
            content: 'Would you like to test before applying the fix?',
            nextSteps: [{
                nextStepId: 'hotfix-testResult',
                onSelected: async () => { return {} },
                label: 'Yes'
            }, {
                nextStepId: 'hotfix-prOrDeploy',
                onSelected: async () => { return {} },
                label: 'No'
            }],
            buttonAlignment: 'horizontal'
        },
        {
            id: 'hotfix-testResult',
            content: 'Were the tests successfull?',
            nextSteps: [{
                nextStepId: 'hotfix-prOrDeploy',
                onSelected: async () => { return {} },
                label: 'Yes'
            }, {
                nextStepId: 'hotfix-testShowcase',
                onSelected: async () => { return {} },
                label: 'No'
            }],
            buttonAlignment: 'horizontal'
        },
        {
            id: 'hotfix-prOrDeploy',
            content: 'Create PR to integration branch or deploy integration?',
            nextSteps: [{
                nextStepId: 'hotfix-prCompleted',
                onSelected: async () => { return {} },
                label: 'Pull Request'
            }, {
                nextStepId: 'hotfix-deployFix',
                onSelected: async () => { return {} },
                label: 'Deploy'
            }],
            buttonAlignment: 'horizontal'
        },
        {
            id: 'hotfix-prCompleted',
            contentRendererKey: 'hotfix-prCompleted',
            nextSteps: [{
                nextStepId: 'hotfix-deployFix',
                onSelected: async () => { return {} },
                label: 'Yes'
            }, {
                nextStepId: 'hotfix-prCompleted',
                onSelected: async () => { return {} },
                label: 'No'
            }],
            buttonAlignment: 'horizontal'
        },
        {
            id: 'hotfix-deployFix',
            contentRendererKey: 'hotfix-deployFix',
            nextSteps: [],
            buttonAlignment: 'horizontal'
        }
    ];

    getProcesses(): Process[] {
        return this.allProcesses;
    }

    getProcessSteps(processId: string): Step[] {
        const stepIds = processStepMap[processId];
        if (!stepIds) {
            throw new Error(`PFS process ${processId} doesn't exist.`);
        }

        const steps: Step[] = [];
        for (const stepId of stepIds) {
            const step = this.allSteps.find(step => step.id == stepId);
            if (!!step) {
                steps.push(step);
            }
        }
        return steps;
    }

    private async createHotfixShowcase(projectName: string, basedOnBranch: string): Promise<{ branchName?: string, showcaseId?: number }> {
        const data = { projectName: projectName, basedOnBranch: basedOnBranch };
        const postResult = await PFSService.processPost('/hotfix/createnewbranch', 'hotfix-createNewBranch', data);
        if (!postResult.ok) {
            return {};
        }
        return postResult.data;
    }
}
