import * as React from 'react';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import LogsPanel from '../../components/LogsPanel/LogsPanel';
import CommandBarRc from '../../components/PFS/CommandBarRc/CommandBarRc';

initializeIcons();

const PFSActionsRcIntegration = () => {
    const [showLogsPanel, setShowLogsPanel] = React.useState<boolean>(false);

    return (
        <>
            <CommandBarRc onShowLogClick={() => setShowLogsPanel(true)}/>
            <LogsPanel 
                isOpen={showLogsPanel}
                onDismiss={() => setShowLogsPanel(false)}
                onIncomingMessage={() => setShowLogsPanel(true)}
            />
        </>
    );
}

export default PFSActionsRcIntegration;