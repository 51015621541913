import * as React from 'react';
import LogsPanel from '../../components/LogsPanel/LogsPanel';
import { EWorkItemType } from '../../Enum';
import CommandBarCustomer from '../../components/PFS/CommandBarCustomer/CommandBarCustomer';
import PFSActionsFallback from './PFSActionsFallback';

type Properties = {
    teamProject: string;
}

const reportheldProjects: string[] = ['com.reportheld.app', 'test.reportheld'];

const PFSActionsBill = (props: Properties) => {
    const [showLogsPanel, setShowLogsPanel] = React.useState<boolean>(false);

    const CommandBarContent = React.useMemo(() => {
        if (!reportheldProjects.includes(props.teamProject)) {
            return (
                <CommandBarCustomer 
                    workItemType={EWorkItemType.BILL} 
                    onLoadLogsPanel={() => {}}
                />
            );
        }
        return <PFSActionsFallback />;
    }, [props.teamProject]);

    return (
        <>
            { CommandBarContent }
            <LogsPanel
                    isOpen={showLogsPanel}
                    onDismiss={() => setShowLogsPanel(false)}
                    onIncomingMessage={() => setShowLogsPanel(true)}
            />
        </>
    )
}

export default PFSActionsBill;