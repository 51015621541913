export enum EWorkItemType {
    TEMPLATE = 'Template',
    CUSTOMER = 'Customer',
    CUSTOMER_REQUEST = 'Customer Request',
    EPIC = 'Epic',
    MILESTONE = 'Milestone',
    RELEASE = 'Release',
    SHOWCASE_INTEGRATION = 'Showcase Integration',
    BETA_INTEGRATION = 'Beta Integration',
    RC_INTEGRATION = 'RC Integration',
    PRODUCTION_INTEGRATION = 'Production Integration',
    DATABASE_DUMP = 'Database Dump',
    USER_STORY = 'User Story',
    TASK = 'Task',
    BUG = 'Bug',
    CHANGE_REQUEST = 'Change Request',
    FEATURE = 'Feature',
    BILL = 'Bill'
}

export enum EWorkItemState {
    NEW = 'New',
    STAGED = 'Staged',
    NOT_VALID = 'Not Valid',
    TRIAGED = 'Triaged',
    DEFERRED = 'Deferred',
    ACTIVE = 'Active',
    READY_FOR_TESTING = 'Ready For Testing',
    TESTED_SUCCESSFUL = 'Tested - Success',
    TESTED_FAILED = 'Tested - Failed',
    PREPARED = 'Prepared',
    RUNNING = 'Running',
    MERGED_INTO_BETA = 'Merged Into Beta',
    RESOLVED = 'Resolved',
    VERIFIED = 'Verified',
    PUBLISHED = 'Published',
    CLOSED = 'Closed',
    REMOVED = 'Removed',
    SKIP = 'Skip'
}

export enum EDeploymentTarget {
    NIGHTLY = 'nightly',
    SHOWCASE = 'alpha',
    BETA = 'beta',
    RC = 'rc',
    PRODUCTION = 'production'
}

export enum ELinkType {
    PARENT = 'System.LinkTypes.Hierarchy-Reverse',
    CHILD = 'System.LinkTypes.Hierarchy-Forward',
    RELATED = 'System.LinkTypes.Related-Forward',
    PREDECESSOR = 'System.LinkTypes.Dependency-Reverse',
    SUCCESSOR = 'System.LinkTypes.Dependency-Forward'
}

export enum EButton {
    NONE = '',
    CREATE_NEXT_RELEASE = 'createNextRelease',
    VALIDATE_WORK_ITEMS = 'validateWorkItems',
    ADD_CUSTOMER_MILESTONE = 'addCustomerMilestone',
    DEPLOY_BETA = 'deployBeta',
    DEPLOY_RC = 'deployRC',
    BUILD_PRODUCTION = 'buildProduction',
    CREATE_DATABASE_DUMP = 'createDatabaseDump',
    RUN_CYPRESS_TESTS = 'runCypressTests',
    RERUN_LAST_BUILD = 'rerunLastBuild',
    CREATE_FOLLOWUP_US = 'createFollowupUS',
    CREATE_USS_FROM_JIRA = 'createUssfromJira',
    CLEANUP_CYPRESS_DATA = 'cleanupCypressData',
    CLONE_TO_REPORTHELD = 'cloneToReportheld',
    CLONE_TO_CUSTOMER = 'cloneToCustomer'
}