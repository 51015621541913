import * as React from 'react';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';

type Properties = {
    onDismiss: () => void
    onMilestoneIdSelected: (milestoneId: number) => void
    milestones: { title: string, id: number, relatedToPredecessor: boolean }[];
}

const MilestoneChoiceGroup = (props: Properties) => {
    async function onMilestoneIdSelected(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): Promise<void> {
        if (!option) {
            return;
        }
        const milestoneId = parseInt(option.key);
        props.onMilestoneIdSelected(milestoneId);
    }

    const options: IChoiceGroupOption[] = React.useMemo(() => {
        return props.milestones.map(milestone => {
            const relatedToPredecessor = milestone.relatedToPredecessor ? `(added to previous Sprint)` : '';

            return {
                key: (milestone.id).toString(),
                text: `${milestone.id} ${relatedToPredecessor}: ${milestone.title}`
            };
        })
    }, [props.milestones]);

    return (
        <ChoiceGroup
            options={options}
            onChange={onMilestoneIdSelected}
            required={true}
        />
    );
}

export default MilestoneChoiceGroup;
