import * as React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import GanttView from '../../screens/Gantt';
import PFSActionsView from '../../screens/PFSActions';
import PFSProcessesView from '../../screens/PFSProcesses';

const Router = () => {
    return (
        <BrowserRouter>
            <Route path="/groupxs-gantt.html" component={GanttView} />
            <Route path="/pfs-release-actions.html" component={PFSActionsView} />
            <Route path="/pfs-processes.html" component={PFSProcessesView} />
        </BrowserRouter>
    );
}

export default Router;