import * as React from 'react';
import { IButtonProps, CommandBar, ICommandBarItemProps, Spinner, SpinnerSize} from '@fluentui/react';
import { EButton, EWorkItemType } from '../../../Enum';
import VSSService from '../../../services/VSSService';
import { hideDialog, loadButtonsInitial, cloneEpic, cloneBill, cloneFeature, cloneUserStory, cloneBug, displayLoading } from './CommandBarCustomer.actions';
import { dataStateReducer, defaultState } from './CommandBarCustomer.reducer';
import CustomerDialog from './CustomerDialog/CustomerDialog';

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

type Properties = {
    workItemType: EWorkItemType;
    onLoadLogsPanel: () => void;
}

const CommandBarCustomer = (props: Properties) => {
    const [state, dispatch] = React.useReducer(dataStateReducer, defaultState());

    React.useEffect(() => {
        loadButtonsInitial(dispatch);

        VSSService.on(`${props.workItemType}:refreshed`, () => {
            loadButtonsInitial(dispatch);
        });
    }, []);


    const onCloneClick = () => {
        displayLoading(dispatch);

        if (props.workItemType == EWorkItemType.EPIC) {
            cloneEpic(dispatch);
        } else if (props.workItemType == EWorkItemType.BILL) {
            cloneBill(dispatch);
        } else if (props.workItemType == EWorkItemType.FEATURE) {
            cloneFeature(dispatch);
        } else if (props.workItemType == EWorkItemType.USER_STORY) {
            cloneUserStory(dispatch);
        } else if (props.workItemType == EWorkItemType.BUG) {
            cloneBug(dispatch);
        }
    };

    const items: ICommandBarItemProps[] = [
        {
            key: 'workitem',
            text: 'Actions',
            iconProps: { iconName: 'Play' },
            subMenuProps: {
                items: [
                    {
                        key: EButton.CLONE_TO_REPORTHELD,
                        text: 'Clone to com.reportheld.app',
                        iconProps: { iconName: 'Link' },
                        disabled: state.cloneButtonDisabled,
                        onClick: onCloneClick
                    }
                ]
            },
        }
    ];

    const LoadSpinner = React.useMemo(() => {
        if (state.isLoading) {
            return (
                <div style={{ 'marginTop': '15rem' }}>
                    <Spinner size={SpinnerSize.large} />
                </div>
            );
        }
        return <></>;
    }, [state.isLoading]);

    return (
        <div>
            <CommandBar
                items={items}
                overflowButtonProps={overflowProps}
            />
            { LoadSpinner }
            <CustomerDialog
                buttonClicked={state.buttonClicked}
                workItemType={props.workItemType}
                data={state.data}
                isHidden={state.hideDialog}
                dialogInfo={state.dialogInfo}
                onDismiss={() => hideDialog(dispatch, true)}
                onShow={() => hideDialog(dispatch, false)}
                onLoadLogsPanel={props.onLoadLogsPanel}
            />
        </div>
    );
};

export default CommandBarCustomer;
