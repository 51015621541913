import * as React from 'react';
import * as _ from 'lodash';

import AuthService from '../services/AuthService';
import PFSService from '../services/PFSService';
import CredentialHelper from '../components/CredentialHelper/CredentialHelper';
import StepsContainer from './PFSProcesses/StepsContainer';

import { initializeIcons } from '@fluentui/react/lib/Icons';
import { Dropdown, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';

import { usePFSProcessesService, withContractContext } from '../contracts/contexts/ContractContext';

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 300 },
    label: { fontSize: "large" }
};

const PFSProcesses = () => {
    const pfsProcessesService = usePFSProcessesService();
    const [isVerified, setIsVerified] = React.useState<boolean>(false);
    const [isVerifying, setIsVerifying] = React.useState<boolean>(true);
    const [selectedItem, setSelectedItem] = React.useState<IDropdownOption>();
    const [isPfsDevEnv, setIsPfsDevEnv] = React.useState<boolean>(true);
    initializeIcons();

    React.useEffect(() => {
        getPfsEnv();
        checkCredentials();
    }, []);

    // TODO: function below is temporary to display "WIP" in the PFS production env
    const getPfsEnv = async () => {
        const envPFS = await PFSService.getEnvironment();
        if (!envPFS.url?.startsWith('https://test.')) {
            setIsPfsDevEnv(false);
        }
    }

    const processes = React.useMemo<IDropdownOption[]>(() => {
        const rawProcesses = pfsProcessesService.getProcesses();
        return _.map(rawProcesses, (process) => ({ key: process.id, text: process.name }));
    }, [pfsProcessesService]);

    const checkCredentials = async () => {
        setIsVerifying(true);
        const hasSetCredentials = AuthService.hasSetCredentials();

        if (hasSetCredentials) {
            const verified = await AuthService.verifyCredentials();
            setIsVerified(verified);
        }

        setIsVerifying(false);
    }

    if (!isPfsDevEnv) {
        return <div>WIP</div>
    }

    if (isVerifying || !isVerified) {
        return <CredentialHelper isVerifying={isVerifying} onSave={checkCredentials} />;
    }

    const onChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
        setSelectedItem(item);
    };

    return (
        <div style={{ margin: "1rem" }}>
            <Dropdown
                selectedKey={selectedItem ? selectedItem.key : undefined}
                placeholder="Choose a PFS process"
                label="I would like to..."
                options={processes}
                styles={dropdownStyles}
                onChange={onChange}
            />
            {selectedItem?.key && <StepsContainer processId={selectedItem.key as string} />}
        </div>
    );
}

export default withContractContext(PFSProcesses);