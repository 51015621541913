import * as React from 'react';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import LogsPanel from '../../components/LogsPanel/LogsPanel';
import CommandBarProduction from '../../components/PFS/CommandBarProduction/CommandBarProduction';

initializeIcons();

const PFSActionsProdIntegration = () => {
    const [showLogsPanel, setShowLogsPanel] = React.useState<boolean>(false);

    return (
        <>
            <CommandBarProduction onShowLogClick={() => setShowLogsPanel(true)}/>
            <LogsPanel
                isOpen={showLogsPanel}
                onDismiss={() => setShowLogsPanel(false)}
                onIncomingMessage={() => setShowLogsPanel(true)}
            />
        </>
    );
}

export default PFSActionsProdIntegration;