import React from 'react';

import { PFSProcessesService } from '../PFSProcesses.contract';
import { PFSProcessesServiceImpl } from '../PFSProcesses.impl';

export type ContractContextType = {
    pfsProcessesService: PFSProcessesService;
};

const contractMocks = {
    pfsProcessesService: new PFSProcessesServiceImpl()
};

const ContractContext = React.createContext<ContractContextType>(contractMocks);

// Create context data
const pfsProcessesServiceInstance = new PFSProcessesServiceImpl();

const contextData: ContractContextType = {
    ...contractMocks,
    pfsProcessesService: pfsProcessesServiceInstance,
};

export const withContractContext = <T extends object>(Component: React.FC<T>): React.FC<T> => (props: T) => {
    return (
        <ContractContext.Provider value={contextData}>
            <Component {...props} />
        </ContractContext.Provider>
    )
};

export const usePFSProcessesService = () => {
    const context = React.useContext(ContractContext);
    return context.pfsProcessesService;
};