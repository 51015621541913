import VSSService from '../../../services/VSSService';
import { ExtensionMessage } from '../../../types/types';

export const getActivityItemStyle = (message: ExtensionMessage) => {
    const theme = VSSService.theme;

    let iconName: string = '';
    let iconColor: string = '';
    let backgroundColor: string = '';
    let textColor: string = '';

    if (message.type == 'info') {
        iconName = 'Message';
        iconColor = '#605e5c';
    } else if (message.type == 'error') {
        iconName = 'ErrorBadge';
        iconColor = '#a80000';
    } else if (message.type == 'success') {
        iconName = 'CheckMark';
        iconColor = '#107c10';
    } else if (message.type == 'warning') {
        iconName = 'Warning';
    }

    if (!theme || theme == "ms.vss-web.vsts-theme") {
        textColor = '#323130';
        if (message.type == 'info') {
            backgroundColor = '#ffffff';
        } else if (message.type == 'error') {
            backgroundColor = '#fde7e9';
        } else if (message.type == 'success') {
            backgroundColor = '#dff6dd';
        } else if (message.type == 'warning') {
            backgroundColor = '#fff4ce';
        }
    } else if (theme == "ms.vss-web.vsts-theme-dark") {
        backgroundColor = '#1b1b1b';
        if (message.type == 'info') {
            textColor = '#edebe9';
        } else if (message.type == 'error') {
            textColor = '#cd594a';
        } else if (message.type == 'success') {
            textColor = '#6dc253';
        } else if (message.type == 'warning') {
            textColor = '#efd469';
        }
    }

    return {
        iconName: iconName,
        iconColor: iconColor,
        backgroundColor: backgroundColor,
        textColor: textColor
    }
}