import { EButton } from "../../../Enum";
import { ButtonsDisabled } from "../../../types/types";

type DialogInfo = {
    endpoint: string;
    title: string;
    subtext: string;
}

export type State = {
    buttonClicked: EButton;
    hideDialog: boolean;
    isLoading: boolean;
    cloneButtonDisabled: boolean;
    dialogInfo: DialogInfo;
    data: {[key: string]: any}
};

export function defaultState(): State {
    return {
        buttonClicked: EButton.NONE,
        cloneButtonDisabled: false,
        hideDialog: true,
        isLoading: false,
        dialogInfo: {
            endpoint: '',
            title: '',
            subtext: ''
        },
        data: {}
    }
}

export type DisplayLoading = {
    type: 'displayLoading';
    isLoading: boolean;
}

export type LoadButtonsInitial = {
    type: 'loadButtons';
    cloneButtonDisabled: boolean;
    hideDialog: boolean;
};

export type HideDialog = {
    type: 'hideDialog';
    hideDialog: boolean;
    isLoading: boolean;
};

export type CloneEpic = {
    type: 'cloneEpic';
    buttonClicked: EButton;
    hideDialog: boolean;
    isLoading: boolean;
    dialogInfo: DialogInfo;
};

export type CloneBill = {
    type: 'cloneBill';
    buttonClicked: EButton;
    hideDialog: boolean;
    isLoading: boolean;
    dialogInfo: DialogInfo;
    data: {
        customerMilestones: { id: number, title: string }[]
    };
}

export type CloneFeature = {
    type: 'cloneFeature';
    buttonClicked: EButton;
    hideDialog: boolean;
    isLoading: boolean;
    dialogInfo: DialogInfo;
    data: {
        sprintNames: string[]
    };
};

export type CloneUserStory = {
    type: 'cloneUserStory';
    buttonClicked: EButton;
    hideDialog: boolean;
    isLoading: boolean;
    dialogInfo: DialogInfo;
    data: {
        sprintNames: string[]
    };
};

export type CloneBug = {
    type: 'cloneBug';
    buttonClicked: EButton;
    hideDialog: boolean;
    isLoading: boolean;
    dialogInfo: DialogInfo;
    data: {
        sprintNames: string[]
    };
};

export type Action = LoadButtonsInitial | DisplayLoading | HideDialog | CloneEpic | CloneBill | CloneFeature | CloneUserStory | CloneBug;

export function dataStateReducer(state: State, action: Action): State {
    switch (action.type) {
        case 'loadButtons': {
            return {
                ...state,
                cloneButtonDisabled: action.cloneButtonDisabled,
                hideDialog: action.hideDialog
            }
        }
        case 'displayLoading': {
            return {
                ...state,
                isLoading: action.isLoading
            }
        }
        case 'hideDialog': {
            return {
                ...state,
                hideDialog: action.hideDialog,
                isLoading: action.isLoading
            }
        }
        case 'cloneEpic': {
            return {
                ...state,
                buttonClicked: action.buttonClicked,
                hideDialog: action.hideDialog,
                isLoading: action.isLoading,
                dialogInfo: action.dialogInfo
            }
        }
        case 'cloneBill': {
            return {
                ...state,
                buttonClicked: action.buttonClicked,
                hideDialog: action.hideDialog,
                isLoading: action.isLoading,
                dialogInfo: action.dialogInfo,
                data: action.data
            }
        }
        case 'cloneFeature': {
            return {
                ...state,
                buttonClicked: action.buttonClicked,
                hideDialog: action.hideDialog,
                isLoading: action.isLoading,
                dialogInfo: action.dialogInfo,
                data: action.data
            }
        }
        case 'cloneUserStory': {
            return {
                ...state,
                buttonClicked: action.buttonClicked,
                hideDialog: action.hideDialog,
                isLoading: action.isLoading,
                dialogInfo: action.dialogInfo,
                data: action.data
            }
        }
        case 'cloneBug': {
            return {
                ...state,
                buttonClicked: action.buttonClicked,
                hideDialog: action.hideDialog,
                isLoading: action.isLoading,
                dialogInfo: action.dialogInfo,
                data: action.data
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${(action as any).type}`);
        }
    }
}

export type Dispatch = (action: Action) => void;
