import React from 'react';
import './css/App.css';
import Router from './components/Router/Router';
// import { AzureThemeDark, AzureThemeLight } from '@fluentui/azure-themes';
import { AzureThemeDark, AzureThemeLight } from '@uifabric/azure-themes';
import { loadTheme } from '@fluentui/react';
import VSSservice from './services/VSSService';

function App() {
    React.useEffect(() => {
        VSS.notifyLoadSucceeded();
        setTheme();
    }, []);

    const setTheme = async () => {
        const theme = await VSSservice.getUserTheme();
        if (theme == "ms.vss-web.vsts-theme-dark") {
            loadTheme(AzureThemeDark);
        } else {
            loadTheme(AzureThemeLight);
        }
    }

    return (
        <Router />
    );
}

export default App;
