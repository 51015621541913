import * as React from 'react';

import AuthService from '../../services/AuthService';

type Properties = {
    isVerifying: boolean,
    onSave: () => void
}

const CredentialHelper = (props: Properties) => {
    const [userName, setUserName] = React.useState<string>('');
    const [userPass, setUserPass] = React.useState<string>('');

    return (
        <div
            style={{ width: '100vw', height: '100vh', display: 'table-cell', textAlign: 'center', verticalAlign: 'middle' }}
            onKeyPress={event => {
                if (event.key === 'Enter') {
                    AuthService.setCredentials(userName, userPass);
                    props.onSave();
                }
            }}
        >
            <h1>Please Login</h1>
            <h3>Azure DevOps Mail</h3>
            <input
                type="text"
                value={userName}
                onChange={e => setUserName(e.target.value)}
            />
            <br />

            <h3>Azure DevOps PAT</h3>
            <input
                type="password"
                value={userPass}
                onChange={e => setUserPass(e.target.value)}
            />
            <br />
            <br />

            <button
                disabled={props.isVerifying}
                onClick={() => {
                    AuthService.setCredentials(userName, userPass);
                    props.onSave();
                }}
            >
                Login!
            </button>
        </div>
    )
}

export default CredentialHelper;