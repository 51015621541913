import * as React from 'react';
import { IButtonProps, CommandBar, ICommandBarItemProps, Spinner, SpinnerSize} from '@fluentui/react';
import { EButton, EWorkItemType } from '../../../Enum';
import { cloneToCustomer, createFollowup, displayLoading, hideDialog, loadInitial } from './CommandBarUserStory.actions';
import { dataStateReducer, defaultState } from './CommandBarUserStory.reducer';
import UserStoryDialog from './UserStoryDialog/UserStoryDialog';
import AuthService from '../../../services/AuthService';
import AdminInput from '../AdminInput/AdminInput';
import VSSService from '../../../services/VSSService';

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

type Properties = {
    onLoadLogsPanel: () => void;
}

const CommandBarUserStory = (props: Properties) => {
    const [state, dispatch] = React.useReducer(dataStateReducer, defaultState());
    const [projectName, setProjectName] = React.useState<string>('');

    React.useEffect(() => {
        loadInitial(dispatch, AuthService.usermail);

        getProjectName();
    }, []);

    const getProjectName = async () => {
        const workItemId = await VSSService.getCurrentWorkItemId();
        const vssProjectName = await VSSService.getWorkItemProjectName(workItemId);
        setProjectName(vssProjectName);
    }

    const items: ICommandBarItemProps[] = [
        {
            key: 'userStory',
            text: 'User Story',
            iconProps: { iconName: 'ReadingMode' },
            subMenuProps: {
                items: [
                    {
                        key: EButton.CREATE_FOLLOWUP_US,
                        text: 'Close & Create follow-up',
                        iconProps: { iconName: 'ReadingMode' },
                        onClick: () => {
                            createFollowup(dispatch);
                        }
                    },{
                        key: EButton.CLONE_TO_CUSTOMER,
                        text: `Clone to com.reportheld.app.${projectName}`,
                        iconProps: { iconName: 'Link' },
                        onClick: () => {
                            cloneToCustomer(dispatch, projectName);
                        }
                    }
                ]
            },
        }
    ];

    const LoadSpinner = React.useMemo(() => {
        if (state.isLoading) {
            return (
                <div style={{ 'marginTop': '15rem' }}>
                    <Spinner size={SpinnerSize.large} />
                </div>
            );
        }
        return <></>;
    }, [state.isLoading]);

    return (
        <div>
            <CommandBar
                items={items}
                overflowButtonProps={overflowProps}
            />
            { LoadSpinner }
            <UserStoryDialog
                buttonClicked={state.buttonClicked}
                isHidden={state.hideDialog}
                dialogInfo={state.dialogInfo}
                onDismiss={() => hideDialog(dispatch, true)}
                onShow={() => hideDialog(dispatch, false)}
                onLoadLogsPanel={props.onLoadLogsPanel}
            />
            <AdminInput 
                workItemType={EWorkItemType.USER_STORY}
            />
        </div>
    );
};

export default CommandBarUserStory;
