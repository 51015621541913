import * as React from 'react';
import GanttHeadRow, { initialParentId, ViewMode } from '../components/GanttHeadRow/GanttHeadRow';
import GanttChart from '../components/GanttChart/GanttChart';

const GanttView = () => {
    const [topLevelId, setTopLevelId] = React.useState<number>(initialParentId);
    const [viewMode, setViewMode] = React.useState<ViewMode>(ViewMode.CurrSprint);

    return (
        <>
            <GanttHeadRow
                onParentIdChange={parentId => setTopLevelId(parentId)}
                onViewModeChange={view => setViewMode(view)}
            />
            <GanttChart
                topParentId={topLevelId}
                viewMode={viewMode}
            />
        </>
    )
}

export default GanttView;