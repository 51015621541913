import _ from "lodash";
import * as React from 'react';
import { Checkbox, Stack } from '@fluentui/react';
import { CustomerSelected } from '../../../../types/types';

type Properties = {
    projectNames: string[];
    customersSelected: CustomerSelected[];
    onCustomerSelected: (newCustomersSelected: CustomerSelected[]) => void;
}

const IntegrationsCheckbox = (props: Properties) => {
    const handleCheckboxChange: any = (event: any) => {
        const name = event.target.name;
        const checked = event.target.checked;

        const newCustomersSelected = [...props.customersSelected];
        const existingCustomerIndex = newCustomersSelected.findIndex(customer => customer.projectName == name);
        if (existingCustomerIndex >= 0) {
            newCustomersSelected[existingCustomerIndex].flag = checked;
        } else {
            newCustomersSelected.push({
                projectName: name,
                flag: checked
            });
        }

        props.onCustomerSelected(newCustomersSelected);
    }

    const getCheckboxes = (projectNames: string[]) => {
        if (!projectNames || projectNames.length == 0) {
            return;
        }

        const integrationsCheckboxes = projectNames.map((projectName, index) => {

            let defaultChecked = false;
            if (props.customersSelected.length > 0) {
                const defaultCheckedFiltered = _.find(props.customersSelected, customerSelected => customerSelected.projectName == projectName)
                if (defaultCheckedFiltered) {
                    defaultChecked = defaultCheckedFiltered.flag;
                }
            }

            return (
                <Checkbox
                    key={index}
                    name={projectName}
                    label={projectName}
                    defaultChecked={defaultChecked}
                    onChange={handleCheckboxChange}
                />
            )
        });
        return integrationsCheckboxes;
    }

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            {getCheckboxes(props.projectNames)}
        </Stack>
    );
}

export default IntegrationsCheckbox;