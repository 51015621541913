import * as React from 'react';
import * as _ from 'lodash';
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import { EButton, EWorkItemType } from '../../../../Enum';
import PFSService from '../../../../services/PFSService';
import SprintChoiceGroup from '../SprintChoiceGroup/SprintChoiceGroup';
import MilestoneChoiceGroup from '../MilestoneChoiceGroup/MilestoneChoiceGroup';

type Properties = {
    buttonClicked: EButton;
    workItemType: EWorkItemType
    isHidden: boolean;
    dialogInfo: {
        endpoint: string;
        title: string;
        subtext: string;
    };
    data: {[key: string]: any};
    onDismiss: () => void;
    onShow: () => void;
    onLoadLogsPanel: () => void;
};

const CustomerDialog = (props: Properties) => {
    const [data, setData] = React.useState<{[key: string]: any}>();

    const onConfirmClick = () => {
        const handlePostToEndpoint = async () => {
            const result = await PFSService.postToEndpoint(props.dialogInfo.endpoint, data);
        }
        handlePostToEndpoint();
        props.onDismiss();
        props.onLoadLogsPanel();
    };

    const getDialogContent = () => {
        if ([EWorkItemType.FEATURE, EWorkItemType.USER_STORY, EWorkItemType.BUG].includes(props.workItemType) && props.data.sprintNames) {
            return (
                <SprintChoiceGroup
                    onDismiss={props.onDismiss}
                    onSprintSelected={(sprintName: string) => setData({sprintName: sprintName})}
                    sprintNames={props.data.sprintNames}
                />
            );
        }

        if (props.workItemType == EWorkItemType.BILL && props.data.customerMilestones) {
            return (
                <MilestoneChoiceGroup
                    onDismiss={props.onDismiss}
                    onMilestoneIdSelected={(milestoneId: number) => setData({milestoneId: milestoneId})}
                    milestones={props.data.customerMilestones}
                />
            );
        }

        return (
            <></>
        );
    };

    const getDialogFooterContent = () => {
        return (
            <DialogFooter>
                <PrimaryButton
                    onClick={onConfirmClick}
                    text="OK" />
                <DefaultButton
                    onClick={props.onDismiss}
                    text="Cancel" />
            </DialogFooter>
        );
    };

    return (
        <Dialog
            hidden={props.isHidden}
            onDismiss={props.onDismiss}
            dialogContentProps={{
                type: DialogType.normal,
                title: props.dialogInfo.title,
                closeButtonAriaLabel: 'Close',
                subText: props.dialogInfo.subtext,
            }}
        >
            { getDialogContent() }
            { getDialogFooterContent() }
        </Dialog>
    );
}


export default CustomerDialog;