import React from 'react'
import CustomerDropdownStep from './CustomerDropdownStep';
import { usePFSProcessesService } from '../../contracts/contexts/ContractContext';
import { StepInfo } from '../../contracts/PFSProcesses.contract';
import { GenericStep } from './GenericStep';
import { addNewStep, dataStateReducer, defaultState, loadInitial } from './StepsContainer.reducer';

export type StepsContainerProps = {
    processId: string;
};

const StepsContainer: React.FC<StepsContainerProps> = ({ processId }) => {
    const pfsProcessesService = usePFSProcessesService();
    const [state, dispatch] = React.useReducer(dataStateReducer, defaultState());

    React.useEffect(() => {
        loadInitial(dispatch, pfsProcessesService, processId);
    }, [pfsProcessesService, processId]);

    const { currentStepIds, allSteps } = state;

    //TODO: remove hard coded links
    const dataContent = React.useMemo(() => {
        if (state.stepsData.branchName && state.stepsData.showcaseId) {
            return (
                <div style={{ fontSize: "large", marginLeft: "3rem"}}>
                    <div style={{ marginBottom: "1rem"}}>
                        Please work on this branch: <span style={{ fontWeight: "bold" }}>{state.stepsData.branchName}</span>
                    </div>
                    <div style={{ marginBottom: "1rem"}}>
                        And go to this <a target="_blank" href={`https://groupxs-test.visualstudio.com/test.reportheld/_workitems/edit/${state.stepsData.showcaseId}/`}>Showcase Integration</a> for work item control.
                    </div>
                </div>
            );
        }
    }, [state.stepsData]);

    const addNewStepImpl = React.useCallback((nextStepInfo: StepInfo) => {
        addNewStep(dispatch, nextStepInfo);
    }, []);

    const addNewStepWithData = React.useCallback((nextStepInfo: StepInfo, data: any) => {
        addNewStep(dispatch, {...nextStepInfo, onSelected: () => nextStepInfo.onSelected(data)});
    }, []);

    const stepsContent = React.useMemo(() => {
        const steps = currentStepIds.map(stepId => {
           return allSteps.find(step => step.id === stepId);
        });

        return steps.map(step => {
            if (!step) return <div>Error on step render</div>;

            if (step.contentRendererKey) {
                if (step.contentRendererKey == 'hotfix-selectProjectName') {
                    return (
                        <CustomerDropdownStep
                            key={step.id}
                            step={step}
                            addNewStep={addNewStepWithData}
                        />
                    );
                }
            }

            return <GenericStep key={step.id} step={step} addNewStep={addNewStepImpl} data={state.stepsData}/>
        });
    }, [allSteps, currentStepIds, addNewStepImpl, addNewStepWithData]);

    // TODO: tmporary style for demo
    console.log(state.stepsData)
    return (
        <div style={{ display: "flex", justifyContent: "left" }}>
            <div>
                {stepsContent}
            </div>
            <div>
                {dataContent}
            </div>
        </div>
    );
}

export default StepsContainer;