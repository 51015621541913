import _ from "lodash";
import axios from 'axios';
import VSSService from '../services/VSSService';
import AuthService from '../services/AuthService';
import SocketService from '../services/SocketService';
import PFSService from '../services/PFSService';
import { EWorkItemType, EWorkItemState, EDeploymentTarget } from '../Enum';

type ChangedFields = {
    ['System.State']: EWorkItemState,
    ['System.WorkItemType']: EWorkItemType,
    [key: string]: any
}

type FieldUpdateArgs = {
    changedFields: ChangedFields,
    id: string,
    [key: string]: any
}

export async function handleFieldUpdates(args: FieldUpdateArgs): Promise<void> {
    const workItemId = parseInt(args.id);
    const workItemState = args.changedFields["System.State"];
    const workItemType = args.changedFields["System.WorkItemType"];

    const isSaved = checkUpdateIsSaved(workItemType);
    if (!isSaved) {
        return;
    }

    if (workItemType == EWorkItemType.USER_STORY || workItemType == EWorkItemType.BUG) {
        if (workItemState == EWorkItemState.RESOLVED) {
            sendMessageStateChanged(workItemType, workItemId, workItemState);
        }
    } else if (workItemType == EWorkItemType.BETA_INTEGRATION) {
        if (workItemState == EWorkItemState.TESTED_SUCCESSFUL) {
            await integrationSetToTested(EDeploymentTarget.BETA);
        } else if (workItemState == EWorkItemState.TESTED_FAILED) {
            await integrationSetToTestedFailed(EDeploymentTarget.BETA);
        }
    } else if (workItemType == EWorkItemType.RC_INTEGRATION) {
        if (workItemState == EWorkItemState.TESTED_SUCCESSFUL) {
            await integrationSetToTested(EDeploymentTarget.RC);
        } else if (workItemState == EWorkItemState.TESTED_FAILED) {
            await integrationSetToTestedFailed(EDeploymentTarget.RC);
        }
    }

}

function checkUpdateIsSaved(workItemType: EWorkItemType): boolean {
    return !!workItemType;
}

async function integrationSetToTested(deploymentTarget: EDeploymentTarget) {
    await PFSService.postToEndpoint(`/${deploymentTarget}/settotestedsuccess`);
}

async function integrationSetToTestedFailed(deploymentTarget: EDeploymentTarget) {
    await PFSService.postToEndpoint(`/${deploymentTarget}/settotestedfailed`);
}

function sendMessageStateChanged(workItemType: EWorkItemType, workItemId: number, workItemState: EWorkItemState) {
    SocketService.messageVSCode(`${workItemType} #${workItemId} has been set to ${workItemState}.`);
}