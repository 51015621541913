import { EButton } from "../../../Enum";
import { ButtonsDisabled, CustomerSelected } from "../../../types/types";

type DialogInfo = {
    endpoint: string;
    title: string;
    subtext: string;
}

export type State = {
    buttonClicked: EButton;
    hideDialog: boolean;
    isLoading: boolean;
    disabledButtons: ButtonsDisabled;
    dialogInfo: DialogInfo;
    data: {[key: string]: any}
};

export function defaultState(): State {
    return {
        buttonClicked: EButton.NONE,
        disabledButtons: {},
        hideDialog: true,
        isLoading: false,
        dialogInfo: {
            endpoint: '',
            title: '',
            subtext: ''
        },
        data: {}
    }
}

export type LoadButtonsInitial = {
    type: 'loadButtons';
    disabledButtons: ButtonsDisabled;
    hideDialog: boolean;
};

export type HideDialog = {
    type: 'hideDialog';
    hideDialog: boolean;
    isLoading: boolean;
};

export type DisplayLoading = {
    type: 'displayLoading';
    isLoading: boolean;
};

export type CreateNextRelease = {
    type: 'createNextRelease';
    buttonClicked: EButton;
    hideDialog: boolean;
    isLoading: boolean;
    dialogInfo: DialogInfo;
};

export type ValidateWorkItems = {
    type: 'validateWorkItems';
    buttonClicked: EButton;
    hideDialog: boolean;
    isLoading: boolean;
    dialogInfo: DialogInfo;
}

export type AddCustomer = {
    type: 'addCustomer';
    buttonClicked: EButton;
    hideDialog: boolean;
    isLoading: boolean;
    dialogInfo: DialogInfo;
    data: {
        projectNames: string[];
    }
};

export type AddCustomerSelected = {
    type: 'addCustomerSelected';
    buttonClicked: EButton;
    hideDialog: boolean;
    isLoading: boolean;
    dialogInfo: DialogInfo;
    data: {
        milestones: { title: string, id: number, relatedToPredecessor: boolean }[];
    }
};

export type DeployBeta = {
    type: 'deployBeta';
    buttonClicked: EButton;
    hideDialog: boolean;
    isLoading: boolean;
    dialogInfo: DialogInfo;
    data: {
        projectNames: string[];
    }
};

export type DeployRc = {
    type: 'deployRc';
    buttonClicked: EButton;
    hideDialog: boolean;
    isLoading: boolean;
    dialogInfo: DialogInfo;
    data: {
        projectNames: string[];
        customersSelected: CustomerSelected[];
    }
};

export type BuildProduction = {
    type: 'buildProduction';
    buttonClicked: EButton;
    hideDialog: boolean;
    isLoading: boolean;
    dialogInfo: DialogInfo;
    data: {
        projectNames: string[];
    }
};

export type Action = LoadButtonsInitial | DisplayLoading | HideDialog | CreateNextRelease | ValidateWorkItems | AddCustomer | AddCustomerSelected | DeployBeta | DeployRc | BuildProduction;

export function dataStateReducer(state: State, action: Action): State {
    switch (action.type) {
        case 'loadButtons': {
            return {
                ...state,
                disabledButtons: action.disabledButtons,
                hideDialog: action.hideDialog
            }
        }
        case 'displayLoading': {
            return {
                ...state,
                isLoading: action.isLoading
            }
        }
        case 'hideDialog': {
            return {
                ...state,
                hideDialog: action.hideDialog,
                isLoading: action.isLoading
            }
        }
        case 'createNextRelease': {
            return {
                ...state,
                buttonClicked: action.buttonClicked,
                hideDialog: action.hideDialog,
                isLoading: action.isLoading,
                dialogInfo: action.dialogInfo
            }
        }
        case 'validateWorkItems': {
            return {
                ...state,
                buttonClicked: action.buttonClicked,
                hideDialog: action.hideDialog,
                isLoading: action.isLoading,
                dialogInfo: action.dialogInfo
            }
        }
        case 'addCustomer': {
            return {
                ...state,
                buttonClicked: action.buttonClicked,
                hideDialog: action.hideDialog,
                isLoading: action.isLoading,
                dialogInfo: action.dialogInfo,
                data: action.data
            }
        }
        case 'addCustomerSelected': {
            return {
                ...state,
                buttonClicked: action.buttonClicked,
                hideDialog: action.hideDialog,
                isLoading: action.isLoading,
                dialogInfo: action.dialogInfo,
                data: action.data
            }
        }
        case 'deployBeta': {
            return {
                ...state,
                buttonClicked: action.buttonClicked,
                hideDialog: action.hideDialog,
                isLoading: action.isLoading,
                dialogInfo: action.dialogInfo,
                data: action.data
            }
        }
        case 'deployRc': {
            return {
                ...state,
                buttonClicked: action.buttonClicked,
                hideDialog: action.hideDialog,
                isLoading: action.isLoading,
                dialogInfo: action.dialogInfo,
                data: action.data
            }
        }
        case 'buildProduction': {
            return {
                ...state,
                buttonClicked: action.buttonClicked,
                hideDialog: action.hideDialog,
                isLoading: action.isLoading,
                dialogInfo: action.dialogInfo,
                data: action.data
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${(action as any).type}`);
        }
    }
}

export type Dispatch = (action: Action) => void;
