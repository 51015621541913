import * as React from 'react';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';

type Properties = {
    onDismiss: () => void
    onSprintSelected: (iterationPath: string) => void
    sprintNames: string[];
}

const SprintChoiceGroup = (props: Properties) => {
    async function onSprintSelected(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): Promise<void> {
        if (!option) {
            return;
        }
        const sprintName = option.key;
        props.onSprintSelected(sprintName);
    }

    const options: IChoiceGroupOption[] = React.useMemo(() => {
        return props.sprintNames.map(sprintName => {
            return {
                key: sprintName,
                text: sprintName
            };
        })
    }, [props.sprintNames]);

    return (
        <ChoiceGroup
            options={options}
            onChange={onSprintSelected}
            required={true}
        />
    );
}

export default SprintChoiceGroup;
