import * as React from 'react';
import { getActivityItemStyle } from './LogMessage.logic';
import { ExtensionMessage, ExtensionMessageAction } from '../../../types/types';
import { ActivityItem, Icon, Link } from '@fluentui/react';

type Properties = {
    index: number;
    message: ExtensionMessage;
};

const getMessageText = (index: number, text: string, backgroundColor: string, textColor: string) => {
    const textStyle = {
        backgroundColor: backgroundColor,
        color: textColor
    };

    return (
        <span key={`${index}_${text}`} style={textStyle}>
            {text}
        </span>
    );
}

const getMessageActions = (actions: ExtensionMessageAction[]) => {
    const messageActions = actions.map( (action, index) => {
        return (
            <div key={index}>
                <Link key={index} href={action.url} target="_blank">
                    {action.text}
                </Link>
            </div>
        );
    });
    return messageActions;
}

const LogMessage = (props: Properties) => {
    const { iconName, iconColor, backgroundColor, textColor } = getActivityItemStyle(props.message);

    const text = getMessageText(props.index, props.message.text, backgroundColor, textColor);
    const activityDescription = [text];

    if (props.message.actions) {
        const actions = getMessageActions(props.message.actions);
        activityDescription.push(...actions);
    }

    const activityIcon = <Icon iconName={iconName} style={{ color: iconColor }} />;
    const timeStamp = new Date().toLocaleString('en-GB');

    return (
        <ActivityItem
            key={props.index}
            activityDescription={activityDescription}
            activityIcon={activityIcon}
            timeStamp={timeStamp}
        />
    );
}

export default LogMessage;