import { ITextFieldStyles, PrimaryButton, Stack, TextField } from '@fluentui/react';
import * as React from 'react';
import PFSService from '../../../../services/PFSService';
import VSSService from '../../../../services/VSSService';

const textFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: "60%" } };

const AdminEpic = () => {
    const [adminFields, setAdminFields] = React.useState<{ hourlyRate: string }>({ hourlyRate: '' });
    const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(true);
    const [errorMessage, setErrorMessage] = React.useState<string>('');

    React.useEffect(() => {
        const getCurrentFieldValue = async () => {
            const hourlyRateValue = await VSSService.getCurrentWorkItemFieldValue("Custom.HourlyRate");
            setAdminFields({ hourlyRate: hourlyRateValue });
        }
        getCurrentFieldValue();
    }, []);

    const handleHourlyRateChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setButtonDisabled(false);
        if (!newValue) {
            return;
        }
        setAdminFields({ hourlyRate: newValue });
    }

    const handleButtonClick = async () => {
        setButtonDisabled(true);

        const response = await PFSService.postToEndpoint('epic/setadminfields', { adminFields: adminFields });
        if (!response.ok) {
            setErrorMessage('Unable to update field. Please refresh and try again.');
        }
    }

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <TextField 
                label="Hourly Rate"
                onChange={handleHourlyRateChange}
                value={adminFields.hourlyRate}
                styles={textFieldStyles}
                errorMessage={errorMessage}
            />
            <PrimaryButton 
                text="Update"
                onClick={handleButtonClick}
                disabled={buttonDisabled}
                style={{ width: '20%' }}
            />
        </Stack>
    );
};

export default AdminEpic;