import * as _ from 'lodash';
import axios from 'axios';
import SocketService from './SocketService';
import PFSService from './PFSService';

class AuthService {
    get usermail(): string {
        return localStorage.getItem('azureDevOpsUsermail') || '';
    }

    get pat(): string {
        return localStorage.getItem('azureDevOpsPAT') || '';
    }

    setCredentials(usermail: string, pat: string) {
        localStorage.setItem('azureDevOpsUsermail', usermail);
        localStorage.setItem('azureDevOpsPAT', pat);
    }

    hasSetCredentials(): boolean {
        const azureUserMail = this.usermail;
        const azuerUserPat = this.pat;
        return !(_.isEmpty(azureUserMail) || _.isEmpty(azuerUserPat));
    }

    async verifyCredentials(): Promise<boolean> {
        const auth = {
            username: process.env.REACT_APP_PFS_USER || '',
            password: process.env.REACT_APP_PFS_PASS || ''
        };
        const envPFS = await PFSService.getEnvironment();

        try {
            const response = await axios.post(`${envPFS.url}azuredevopsextension/connect`, {
                usermail: this.usermail,
                password: this.pat
            }, {
                auth: auth
            });

            if (response.status == 200) {
                const token = response.data;
                await SocketService.connect(token);
            }
            return response.status == 200;
        } catch (error) {
            console.log(error);
            return false;
        }
    }
}

const authService = new AuthService();
export default authService;