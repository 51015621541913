import * as React from 'react';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';

type Properties = {
    onDismiss: () => void
    onProjectNameSelected: (projectName: string) => void
    projectNames: string[];
}

const ProjectNameChoiceGroup = (props: Properties) => {
    async function onProjectNameSelected(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): Promise<void> {
        if (!option) {
            return;
        }
        const projectName = option.key;
        props.onProjectNameSelected(projectName);
    }

    const options: IChoiceGroupOption[] = React.useMemo(() => {
        return props.projectNames.map(projectName => {
            return {
                key: projectName,
                text: projectName
            };
        })
    }, [props.projectNames]);

    return (
        <ChoiceGroup
            options={options}
            onChange={onProjectNameSelected}
            required={true}
        />
    );
}
export default ProjectNameChoiceGroup;
