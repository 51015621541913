import * as _ from 'lodash';
import { EButton, ELinkType, EWorkItemState, EWorkItemType } from '../../../Enum';
import PFSService from '../../../services/PFSService';
import VSSService from '../../../services/VSSService';
import { ButtonsDisabled, CustomerSelected } from '../../../types/types';
import { Dispatch } from './CommandBarRelease.reducer';

export async function loadButtonsInitial(dispatch: Dispatch) {
    const buttonsDisabled = await calculateReleaseButtonsDisabled();

    dispatch({
        type: 'loadButtons',
        disabledButtons: buttonsDisabled,
        hideDialog: true,
    });
}

export async function hideDialog(dispatch: Dispatch, hideDialog: boolean) {
    dispatch({
        type: 'hideDialog',
        hideDialog: hideDialog,
        isLoading: false
    });
}

export async function displayLoading(dispatch: Dispatch) {
    dispatch({
        type: 'displayLoading',
        isLoading: true
    });
}

export async function displayCreateNextRelease(dispatch: Dispatch) {
    dispatch({
        type: 'createNextRelease',
        buttonClicked: EButton.CREATE_NEXT_RELEASE,
        hideDialog: false,
        isLoading: false,
        dialogInfo: {
            endpoint: 'release/next',
            title: 'Create Next Sprint Release',
            subtext: 'This will create the next sprint Release work item.'
        }
    });
}

export async function displayValidateWorkItems(dispatch: Dispatch) {
    dispatch({
        type: 'validateWorkItems',
        buttonClicked: EButton.VALIDATE_WORK_ITEMS,
        hideDialog: false,
        isLoading: false,
        dialogInfo: {
            endpoint: 'release/validate',
            title: 'Validate Work Items',
            subtext: 'This action will link any missing related Milestone and run a few checks on the Release children work items.'
        }
    });
}

export async function displayAddCustomer(dispatch: Dispatch) {
    const result = await PFSService.postToEndpoint("release/addcustomer");
    const projectNames: string[] = [];

    if (result.ok) {
        projectNames.push(...result.data);
    }

    dispatch({
        type: 'addCustomer',
        buttonClicked: EButton.ADD_CUSTOMER_MILESTONE,
        hideDialog: false,
        isLoading: false,
        dialogInfo: {
            endpoint: 'release/addcustomer',
            title: 'Add Customer Milestone',
            subtext: 'Choose which customer you want to add to this Sprint:'
        },
        data: {
            projectNames: projectNames
        }
    });
}

export async function displayAddCustomerSelected(dispatch: Dispatch, projectName: string) {
    const result = await PFSService.postToEndpoint("release/addcustomer", { customerAdded: projectName });
    const milestones: { title: string, id: number, relatedToPredecessor: boolean }[] = [];

    if (result.ok) {
        milestones.push(...result.data);
    }

    dispatch({
        type: 'addCustomerSelected',
        buttonClicked: EButton.ADD_CUSTOMER_MILESTONE,
        hideDialog: false,
        isLoading: false,
        dialogInfo: {
            endpoint: 'release/addcustomer',
            title: 'Add Customer Milestone',
            subtext: 'Choose a Milestone to be linked as related:'
        },
        data: {
            milestones: milestones
        }
    });
}

export async function displayDeployBeta(dispatch: Dispatch) {
    const milestones = await VSSService.getLinkedWorkItems(ELinkType.RELATED, [EWorkItemType.MILESTONE]);
    const milestonesProjectNames = milestones.map(milestone => milestone.projectName);

    const closedBetas = await VSSService.getWorkItemsSetToStates(ELinkType.CHILD, [EWorkItemType.BETA_INTEGRATION], [EWorkItemState.CLOSED, EWorkItemState.REMOVED, EWorkItemState.TESTED_SUCCESSFUL]);
    const closedBetasProjectNames = closedBetas.map(beta => beta.projectName);

    let projectNames: string[] = [];
    let title = 'Error';
    let subtext = '';
    if (closedBetas.length > 0 && closedBetas.length == _.uniq(milestonesProjectNames).length) {
        subtext = `Beta deployment has already happened for this Sprint. All Beta Integrations are in a closed state.`;
    } else if (milestones.length == 0) {
        subtext = `There are no Milestones linked as related to this Sprint. Please correct this and try again.`
    } else {
        projectNames = _.difference(_.uniq(milestonesProjectNames), closedBetasProjectNames);
        subtext = 'Skip the Database Dump in the Beta deployment for customers:';
        title = 'Beta - Skip Database Dump';
    }

    dispatch({
        type: 'deployBeta',
        buttonClicked: EButton.DEPLOY_BETA,
        hideDialog: false,
        isLoading: false,
        dialogInfo: {
            endpoint: 'release/deploybeta',
            title: title,
            subtext: subtext
        },
        data: {
            projectNames: projectNames
        }
    });
}

export async function displayDeployRc(dispatch: Dispatch) {
    const betas = await VSSService.getLinkedWorkItems(ELinkType.CHILD, [EWorkItemType.BETA_INTEGRATION]);
    const testedBetas = await VSSService.getWorkItemsSetToStates(ELinkType.CHILD, [EWorkItemType.BETA_INTEGRATION], [EWorkItemState.TESTED_SUCCESSFUL, EWorkItemState.CLOSED, EWorkItemState.REMOVED]);

    let projectNames: string[] = [];
    let title = 'Error';
    let subtext = '';
    if (betas.length > 0 && betas.length == testedBetas.length) {
        const projectNamesMapped = testedBetas.map(beta => {
            if (beta.state == EWorkItemState.TESTED_SUCCESSFUL || beta.state == EWorkItemState.CLOSED) {
                return beta.projectName;
            }
        });

        projectNames = _.compact(projectNamesMapped);
        subtext = 'Skip the Database Dump in the RC deployment for customers:';
        title = 'RC - Skip Database Dump';
    } else {
        subtext = `Beta deployment is the current state of this Sprint. RC cannot be deployed.`;
    }

    const skipRcFromBeta = await getSkipRcFromBeta(projectNames);

    dispatch({
        type: 'deployRc',
        buttonClicked: EButton.DEPLOY_RC,
        hideDialog: false,
        isLoading: false,
        dialogInfo: {
            endpoint: 'release/deployrc',
            title: title,
            subtext: subtext
        },
        data: {
            projectNames: projectNames,
            customersSelected: skipRcFromBeta
        }
    });
}

export async function displayBuildProduction(dispatch: Dispatch) {
    const productions = await VSSService.getLinkedWorkItems(ELinkType.CHILD, [EWorkItemType.PRODUCTION_INTEGRATION]);
    const productionProjectNames = productions.map(production => production.projectName);

    const testedRCs = await VSSService.getWorkItemsSetToStates(ELinkType.CHILD, [EWorkItemType.RC_INTEGRATION], [EWorkItemState.TESTED_SUCCESSFUL]);
    const rcProjectNames = testedRCs.map(rc => rc.projectName);

    let projectNames: string[] = [];
    let title = 'Error';
    let subtext = '';
    if (rcProjectNames.length == 0) {
        subtext = `RC Integrations are all Closed or there are none tested successfully yet. Customers can only be deployed to Production after RC has been tested.`;
    } else {
        projectNames = _.difference(rcProjectNames, productionProjectNames);
        title = 'Build Production';
        subtext = 'Select which customer(s) will be built to Production:'
    }

    dispatch({
        type: 'buildProduction',
        buttonClicked: EButton.BUILD_PRODUCTION,
        hideDialog: false,
        isLoading: false,
        dialogInfo: {
            endpoint: 'release/buildproduction',
            title: title,
            subtext: subtext
        },
        data: {
            projectNames: projectNames
        }
    });
}

async function calculateReleaseButtonsDisabled(): Promise<ButtonsDisabled> {
    const childrenWorkItems = await VSSService.getLinkedWorkItems(ELinkType.CHILD, [EWorkItemType.BETA_INTEGRATION, EWorkItemType.RC_INTEGRATION, EWorkItemType.PRODUCTION_INTEGRATION]);

    const disabledAddCustomer = disableDeployBeta(childrenWorkItems);
    const disabledBuildProduction = disableBuildProduction(childrenWorkItems);
    const disabledDeployRC = disableDeployRC(childrenWorkItems);
    const disabledDeployBeta = disableDeployBeta(childrenWorkItems);

    return {
        addCustomer: disabledAddCustomer,
        deployBeta: disabledDeployBeta,
        deployRC: disabledDeployRC,
        buildProduction: disabledBuildProduction
    }
}

function disableBuildProduction(childrenWorkItems: { id: number, projectName: string, type: EWorkItemType, state: EWorkItemState }[]): boolean {
    const testedRcIntegrations = childrenWorkItems.filter(child => child.type == EWorkItemType.RC_INTEGRATION && child.state == EWorkItemState.TESTED_SUCCESSFUL);
    if (testedRcIntegrations.length > 0) {
        return false;
    }
    return true;
}

function disableDeployRC(childrenWorkItems: { id: number, projectName: string, type: EWorkItemType, state: EWorkItemState }[]): boolean {
    const betaIntegrations = childrenWorkItems.filter(child => child.type == EWorkItemType.BETA_INTEGRATION);
    if (betaIntegrations.length == 0) {
        return true;
    }

    const openBetaIntegrations = childrenWorkItems.filter(child => child.type == EWorkItemType.BETA_INTEGRATION && ![EWorkItemState.TESTED_SUCCESSFUL, EWorkItemState.CLOSED, EWorkItemState.REMOVED].includes(child.state));
    if (openBetaIntegrations.length > 0) {
        return true;
    }

    const rcIntegrations = childrenWorkItems.filter(child => child.type == EWorkItemType.RC_INTEGRATION);
    const openRcIntegrations = childrenWorkItems.filter(child => child.type == EWorkItemType.RC_INTEGRATION && ![EWorkItemState.READY_FOR_TESTING, EWorkItemState.TESTED_SUCCESSFUL, EWorkItemState.TESTED_FAILED, EWorkItemState.CLOSED, EWorkItemState.REMOVED].includes(child.state));
    if (rcIntegrations.length > 0 && openRcIntegrations.length == 0) {
        return true;
    }

    return false;
}

function disableDeployBeta(childrenWorkItems: { id: number, projectName: string, type: EWorkItemType, state: EWorkItemState }[]): boolean {
    const rcIntegrations = childrenWorkItems.filter(child => child.type == EWorkItemType.RC_INTEGRATION);
    if (rcIntegrations.length > 0) {
        return true;
    }

    const betaIntegrations = childrenWorkItems.filter(child => child.type == EWorkItemType.BETA_INTEGRATION);
    const closedBetaIntegrations = betaIntegrations.filter(beta => [EWorkItemState.CLOSED, EWorkItemState.REMOVED, EWorkItemState.TESTED_SUCCESSFUL].includes(beta.state));
    if (betaIntegrations.length > 0 && betaIntegrations.length == closedBetaIntegrations.length) {
        return true;
    }

    return false;
}

async function getSkipRcFromBeta(projectNames: string[]): Promise<CustomerSelected[]> {
    const testedBetas = await VSSService.getWorkItemsSetToStates(ELinkType.CHILD, [EWorkItemType.BETA_INTEGRATION], [EWorkItemState.TESTED_SUCCESSFUL, EWorkItemState.CLOSED, EWorkItemState.REMOVED]);

    const customersAvailableMapped: (CustomerSelected | undefined)[] = await Promise.all(
        _.map(projectNames, async projectName => {
            const betaIntegration = _.find(testedBetas, beta => beta.projectName == projectName);

            if (betaIntegration) {
                const skipDatabaseDumpRc = await VSSService.getWorkItemFieldValue(betaIntegration.id, 'Custom.SkipDatabaseDumponRC');
                return {
                    projectName: projectName,
                    flag: skipDatabaseDumpRc
                }
            }
        })
    )
    const customersAvailable = _.compact(customersAvailableMapped);
    return customersAvailable;
}
