import _ from "lodash";
import VSSService from '../services/VSSService';
import { EWorkItemType } from '../Enum';

export async function handleRefresh(): Promise<void> {
    const workItemType = await VSSService.getCurrentWorkItemType();

    const relevantTypes = [
        EWorkItemType.RELEASE,
        EWorkItemType.BETA_INTEGRATION,
        EWorkItemType.USER_STORY,
        EWorkItemType.BUG,
        EWorkItemType.EPIC,
        EWorkItemType.FEATURE,
    ];

    if (_.includes(relevantTypes, workItemType)) {
        emitRefreshedEvent(workItemType);
    }
}

function emitRefreshedEvent(workItemType: EWorkItemType) {
    VSSService.emit(`${workItemType}:refreshed`);
}