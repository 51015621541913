import * as React from 'react';
import * as _ from 'lodash';
import VSSService from '../services/VSSService';
import AuthService from '../services/AuthService';

import CredentialHelper from '../components/CredentialHelper/CredentialHelper';

import PFSActionLoading from './PFSActions/PFSActionsLoading';
import PFSActionFallback from './PFSActions/PFSActionsFallback';
import PFSActionsRelease from './PFSActions/PFSActionsRelease';
import PFSActionsBetaIntegration from './PFSActions/PFSActionsBetaIntegration';
import PFSActionsRcIntegration from './PFSActions/PFSActionsRcIntegration';
import PFSActionsProdIntegration from './PFSActions/PFSActionsProdIntegration';
import PFSActionsUserStory from './PFSActions/PFSActionsUserStory';
import PFSActionsEpic from './PFSActions/PFSActionsEpic';
import PFSActionsFeature from './PFSActions/PFSActionsFeature';
import PFSActionsBill from './PFSActions/PFSActionsBill';
import PFSActionsBug from './PFSActions/PFSActionsBug';
import PFSConquerWorldDemo from './PFSActions/PFSConquerWorldDemo';

import { initializeIcons } from '@fluentui/react/lib/Icons';

const PFSActions = () => {
    const [currentWorkItemType, setCurrentWorkItemType] = React.useState<string>('');
    const [currentTeamProject, setCurrentTeamProject] = React.useState<string>('');
    const [isVerified, setIsVerified] = React.useState<boolean>(false);
    const [isVerifying, setIsVerifying] = React.useState<boolean>(true);

    React.useEffect(() => {
        loadWorkItemTypeAndProject();
        checkCredentials();
    }, []);

    const loadWorkItemTypeAndProject = async () => {
        const teamProject = await VSSService.getCurrentWorkItemFieldValue('System.TeamProject');
        setCurrentTeamProject(teamProject);

        const workItemType = await VSSService.getCurrentWorkItemType();
        setCurrentWorkItemType(workItemType);
    }

    const checkCredentials = async () => {
        setIsVerifying(true);
        const hasSetCredentials = AuthService.hasSetCredentials();
        if (hasSetCredentials) {
            const verified = await AuthService.verifyCredentials();
            setIsVerified(verified);
        }

        setIsVerifying(false);
    }

    initializeIcons();
    function getMatchingView() {
        if (isVerifying || !currentWorkItemType) {
            return <PFSActionLoading />;
        } else if (!isVerified) {
            return <CredentialHelper isVerifying={isVerifying} onSave={checkCredentials} />;
        }

        switch (currentWorkItemType) {
            case 'Epic':
                return <PFSActionsEpic teamProject={currentTeamProject} />;
            case 'Bill':
                return <PFSActionsBill teamProject={currentTeamProject} />;
            case 'Feature':
                return <PFSActionsFeature teamProject={currentTeamProject} />;
            case 'Release':
                return <PFSActionsRelease />;
            case 'Beta Integration':
                return <PFSActionsBetaIntegration />;
            case 'RC Integration':
                return <PFSActionsRcIntegration />;
            case 'Production Integration':
                return <PFSActionsProdIntegration />;
            case 'User Story':
                return <PFSActionsUserStory teamProject={currentTeamProject} />;
            case 'Bug':
                return <PFSActionsBug teamProject={currentTeamProject} />;
            default:
                return <PFSActionFallback />;
        }
    }

    // return <PFSConquerWorldDemo />;
    return (
        <div>
            {getMatchingView()}
        </div>
    );
}

export default PFSActions;