import { io, Socket } from 'socket.io-client';
import VSSService from '../services/VSSService';
import PFSService from '../services/PFSService';

class SocketService {
    private socket: Socket | undefined;
    private token: string = '';
    private listeners: { id: string, callback: (message: any) => void }[];

    constructor() {
        this.listeners = [];
    }

    async connect(token: string) {
        const envPfsUrl = await this.getPfsUrl();

        this.token = token;
        this.socket = io(`${envPfsUrl}`, {
            path: '/azuredevopsextension/socket',
            auth: {
                username: process.env.REACT_APP_PFS_USER || '',
                password: process.env.REACT_APP_PFS_PASS || '',
                token: this.token,
                room: 'azure'
            }
        });

        this.socket.on('connect', () => {
            console.log('connected!')
        });

        this.socket.on('message', (message: any) => {
            if (!message.type) {
                return;
            }
            this.listeners.forEach(listener => listener.callback(message));
        });
    }

    messageVSCode(text: string) {
        if (!this.socket) {
            return;
        }
        this.socket.emit('message', { room: 'vscode', text: text });
    }

    getToken(): string {
        return this.token;
    }

    addListener(callback: (message: any) => void): string {
        const id = this.uuidv4();
        this.listeners.push({ id, callback });
        return id;
    }

    removeListener(id: string) {
        this.listeners = this.listeners.filter(listener => listener.id !== id);
    }

    async getPfsUrl(): Promise<string> {
        const envPFS = await PFSService.getEnvironment();
        const envPfsUrl = envPFS.url!.replace('/azuredevopsextension/', '');
        return envPfsUrl;
    }

    private uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}

const socketService = new SocketService();
export default socketService;