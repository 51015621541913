import React from 'react'
import { Label } from '@fluentui/react/lib/Label';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { Step, StepInfo } from '../../contracts/PFSProcesses.contract';

type GenericStepProps = {
    step: Step;
    addNewStep: (nextStepInfo: StepInfo) => void;
    data?: any;
};

export const GenericStep: React.FC<GenericStepProps> = ({ step, addNewStep, data }) => {
    return (
        <div key={step.id} style={{ margin: "1rem" }}>
            <Label>{step.content}</Label>

            <div>
                {step.nextSteps?.map((nextStep, index) => {
                    const onClick = () => {
                        if (step.id == 'hotfix-rcOrProd' && !!data.customer) {
                            const projectName = data.customer;
                            return addNewStep({...nextStep, onSelected: () => nextStep.onSelected(projectName)});
                        }
                        return addNewStep(nextStep);
                    };

                    return <DefaultButton
                        key={`${nextStep.nextStepId}-${nextStep.label}`}
                        text={nextStep.label}
                        onClick={onClick}
                    />
                })}
            </div>
        </div>
    )
}
