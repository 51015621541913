import { EButton } from '../../../Enum';
import PFSService from '../../../services/PFSService';
import VSSService from '../../../services/VSSService';
import { Dispatch } from './CommandBarCustomer.reducer';

export async function loadButtonsInitial(dispatch: Dispatch) {
    const cloneButtonDisabled = await isCloneButtonDisabled();

    dispatch({
        type: 'loadButtons',
        cloneButtonDisabled: cloneButtonDisabled,
        hideDialog: true,
    });
}

export async function displayLoading(dispatch: Dispatch) {
    dispatch({
        type: 'displayLoading',
        isLoading: true
    });
}

export async function hideDialog(dispatch: Dispatch, hideDialog: boolean) {
    dispatch({
        type: 'hideDialog',
        hideDialog: hideDialog,
        isLoading: false
    });
}

export async function cloneEpic(dispatch: Dispatch) {
    dispatch({
        type: 'cloneEpic',
        buttonClicked: EButton.CLONE_TO_REPORTHELD,
        hideDialog: false,
        isLoading: false,
        dialogInfo: {
            endpoint: 'customer/epic/clone',
            title: 'Clone to Reportheld',
            subtext: 'Epics correspond to Milestones in reportheld. This action will clone this Epic into a Milestone as a child of the Customer work item.'
        }
    });
}

export async function cloneBill(dispatch: Dispatch) {
    const endpoint = 'customer/bill/clone';
    const result = await PFSService.postToEndpoint(endpoint);
    const customerMilestones: { id: number, title: string}[] = [];

    if (result.ok) {
        customerMilestones.push(...result.data.customerMilestones);
    }

    // FIXME: handle the cases where no milestones exist yet
    dispatch({
        type: 'cloneBill',
        buttonClicked: EButton.CLONE_TO_REPORTHELD,
        hideDialog: false,
        isLoading: false,
        dialogInfo: {
            endpoint: endpoint,
            title: 'Clone to Reportheld',
            subtext: 'Bills correspond to Epics in the Reportheld Project. Please choose the parent Milestone for the cloned work item:'
        },
        data: {
            customerMilestones: customerMilestones
        }
    });
}

export async function cloneFeature(dispatch: Dispatch) {
    const endpoint = 'customer/feature/clone';
    const result = await PFSService.postToEndpoint(endpoint);
    if (!result.ok) {
        return;
    }

    const sprintNames = await getSprints(result.data.iterationPaths);

    dispatch({
        type: 'cloneFeature',
        buttonClicked: EButton.CLONE_TO_REPORTHELD,
        hideDialog: false,
        isLoading: false,
        dialogInfo: {
            endpoint: endpoint,
            title: 'Clone to Reportheld',
            subtext: 'This action will create clones of the children User Stories linked to this Feature. Please choose the sprint these USs belong to:'
        },
        data: {
            sprintNames: sprintNames
        }
    });
}

export async function cloneUserStory(dispatch: Dispatch) {
    const endpoint = 'customer/userstory/clone';
    const result = await PFSService.postToEndpoint(endpoint);
    if (!result.ok) {
        return;
    }

    const sprintNames = await getSprints(result.data.iterationPaths);

    dispatch({
        type: 'cloneUserStory',
        buttonClicked: EButton.CLONE_TO_REPORTHELD,
        hideDialog: false,
        isLoading: false,
        dialogInfo: {
            endpoint: endpoint,
            title: 'Clone to Reportheld',
            subtext: 'Please choose the Sprint this User Story belongs to:'
        },
        data: {
            sprintNames : sprintNames
        }
    });
}

export async function cloneBug(dispatch: Dispatch) {
    const endpoint = 'customer/bug/clone';
    const result = await PFSService.postToEndpoint(endpoint);
    if (!result.ok) {
        return;
    }

    const sprintNames = await getSprints(result.data.iterationPaths);

    dispatch({
        type: 'cloneBug',
        buttonClicked: EButton.CLONE_TO_REPORTHELD,
        hideDialog: false,
        isLoading: false,
        dialogInfo: {
            endpoint: endpoint,
            title: 'Clone to Reportheld',
            subtext: 'Please select the sprint this Bug belongs to:'
        },
        data: {
            sprintNames: sprintNames
        }
    });
}

async function isCloneButtonDisabled(): Promise<boolean> {
    const cloneID = await VSSService.getCurrentWorkItemFieldValue('Custom.CloneID');

    if (!!cloneID) {
        return true;
    }
    return false;
}

async function getSprints(iterationPaths: string[]): Promise<string[]> {
    const sprintNames = iterationPaths.map(iterationPath => {
        return iterationPath.split('\\')[2];
    });
    return sprintNames;
}