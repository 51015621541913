import * as React from 'react';
import * as _ from 'lodash';
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import { EButton } from '../../../../Enum';
import PFSService from '../../../../services/PFSService';

type Properties = {
    buttonClicked: EButton;
    isHidden: boolean;
    dialogInfo: {
        endpoint: string;
        title: string;
        subtext: string;
    };
    data: {[key: string]: any};
    onDismiss: () => void;
    onShow: () => void;
    onDisplayForceDump: () => void;
    onLoadLogsPanel: () => void;
};

const BetaDialog = (props: Properties) => {
    const [data, setData] = React.useState<{[key: string]: any}>();

    React.useEffect(() => {
        setData(props.data);
    }, [props.dialogInfo, props.isHidden]);

    const onConfirmClick = () => {
        const handlePostToEndpoint = async () => {
            const result = await PFSService.postToEndpoint(props.dialogInfo.endpoint, data);
            if (!result.ok && result.data?.forceDump) {
                props.onDisplayForceDump();
                setData({ forceDump: true });
            }
        }
        handlePostToEndpoint();
        props.onDismiss();
        props.onLoadLogsPanel();
    };

    const onCancelClick = () => {
        props.onDismiss();
        setData(undefined);
    }

    const getDialogFooterContent = () => {
        return (
            <DialogFooter>
                <PrimaryButton
                    onClick={onConfirmClick}
                    text="OK" />
                <DefaultButton
                    onClick={onCancelClick}
                    text="Cancel" />
            </DialogFooter>
        );
    };

    return (
        <Dialog
            hidden={props.isHidden}
            onDismiss={props.onDismiss}
            dialogContentProps={{
                type: DialogType.normal,
                title: props.dialogInfo.title,
                closeButtonAriaLabel: 'Close',
                subText: props.dialogInfo.subtext,
            }}
        >
            { getDialogFooterContent() }
        </Dialog>
    );
}

export default BetaDialog;