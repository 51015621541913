import { EWorkItemState, EWorkItemType } from "../Enum";
import PFSService from "../services/PFSService";
import VSSService from "../services/VSSService";

type WorkItemLoadedArgs = {
    id: number;
    [key: string]: unknown;
};

export async function handleOnLoaded(args: WorkItemLoadedArgs) {
    const workItemId = args.id;
    const wi = await VSSService.getWorkItemById(workItemId);

    if (wi.fields['System.WorkItemType'] === EWorkItemType.PRODUCTION_INTEGRATION && wi.fields['System.State'] === EWorkItemState.PREPARED) {
        await PFSService.postToEndpoint('production/hashmobileprovision');
    }
};