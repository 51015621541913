import { Spinner, SpinnerSize } from '@fluentui/react';
import * as React from 'react';

const PFSActionsLoading = () => {
    return (
        <div
            data-testid="pfsActionsLoading-container"
            style={{ display: 'table-cell', width: '100vw', height: '100vh', verticalAlign: 'middle', textAlign: 'center' }}
        >
            <Spinner size={SpinnerSize.large} />
        </div>
    )
}

export default PFSActionsLoading;