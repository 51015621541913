import * as React from 'react';
import { IButtonProps, CommandBar, ICommandBarItemProps, Spinner, SpinnerSize } from '@fluentui/react';
import { EButton, EWorkItemType } from '../../../Enum';
import VSSService from '../../../services/VSSService';
import { displayAddCustomer, displayAddCustomerSelected, displayBuildProduction, displayCreateNextRelease, displayDeployBeta, displayDeployRc, displayLoading, displayValidateWorkItems, hideDialog, loadButtonsInitial } from './CommandBarRelease.actions';
import { dataStateReducer, defaultState } from './CommandBarRelease.reducer';
import ReleaseDialog from './ReleaseDialog/ReleaseDialog';

type Properties = {
    onShowLogClick: () => void;
    onLoadLogsPanel: () => void;
}

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

const CommandBarRelease = (props: Properties) => {
    const [state, dispatch] = React.useReducer(dataStateReducer, defaultState());

    React.useEffect(() => {
        loadButtonsInitial(dispatch);

        VSSService.on(`${EWorkItemType.RELEASE}:refreshed`, () => {
            loadButtonsInitial(dispatch);
        });
    }, []);

    const items: ICommandBarItemProps[] = [
        {
            key: 'sprint',
            text: 'Sprint',
            iconProps: { iconName: 'Sprint' },
            subMenuProps: {
                items: [
                    {
                        key: EButton.CREATE_NEXT_RELEASE,
                        text: 'Create Next Release',
                        iconProps: { iconName: 'Giftbox' },
                        onClick: () => {
                            displayLoading(dispatch);
                            displayCreateNextRelease(dispatch);

                        }
                    },
                    {
                        key: EButton.VALIDATE_WORK_ITEMS,
                        text: 'Validate Work Items',
                        iconProps: { iconName: 'Giftbox' },
                        onClick: () => {
                            displayLoading(dispatch);
                            displayValidateWorkItems(dispatch);
                        }
                    },
                    {
                        key: EButton.ADD_CUSTOMER_MILESTONE,
                        text: 'Add Customer Milestone',
                        iconProps: { iconName: 'Giftbox' },
                        disabled: state.disabledButtons.addCustomer,
                        onClick: () => {
                            displayLoading(dispatch);
                            displayAddCustomer(dispatch);
                        }
                    }
                ],
            },
        },
        {
            key: 'build',
            text: 'Build & Deploy',
            iconProps: { iconName: 'Parachute' },
            subMenuProps: {
                items: [
                    {
                        key: EButton.DEPLOY_BETA,
                        text: 'Deploy Beta',
                        iconProps: { iconName: 'Giftbox' },
                        disabled: state.disabledButtons.deployBeta,
                        onClick: () => {
                            displayLoading(dispatch);
                            displayDeployBeta(dispatch);

                        }
                    },
                    {
                        key: EButton.DEPLOY_RC,
                        text: 'Deploy RC',
                        iconProps: { iconName: 'Giftbox' },
                        disabled: state.disabledButtons.deployRC,
                        onClick: () => {
                            displayLoading(dispatch);
                            displayDeployRc(dispatch);
                        }
                    },
                    {
                        key: EButton.BUILD_PRODUCTION,
                        text: 'Build Production',
                        iconProps: { iconName: 'Giftbox' },
                        disabled: state.disabledButtons.buildProduction,
                        onClick: () => {
                            displayLoading(dispatch);
                            displayBuildProduction(dispatch);
                        }
                    }
                ],
            },
        },
        {
            key: 'showLog',
            text: 'Show Log',
            iconProps: { iconName: 'ContextMenu' },
            onClick: props.onShowLogClick
        },
    ];

    const LoadSpinner = React.useMemo(() => {
        if (state.isLoading) {
            return (
                <div style={{ 'marginTop': '15rem' }}>
                    <Spinner size={SpinnerSize.large} />
                </div>
            );
        }
        return <></>;
    }, [state.isLoading])

    return (
        <>
            <CommandBar
                items={items}
                overflowButtonProps={overflowProps}
            />
            {LoadSpinner}
            <ReleaseDialog 
                buttonClicked={state.buttonClicked}
                data={state.data}
                isHidden={state.hideDialog}
                dialogInfo={state.dialogInfo}
                onDismiss={() => hideDialog(dispatch, true)}
                onShow={() => hideDialog(dispatch, false)}
                onLoadLogsPanel={props.onLoadLogsPanel}
                onProjectNameSelected={(projectName: string) => displayAddCustomerSelected(dispatch, projectName)}
            />
        </>
    );
};

export default CommandBarRelease;