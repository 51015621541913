import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//export function init(containerId: string) {

// wait until vssIsLoaded is set by the VSS.ready function in index.html
function startIt() {
  setTimeout(() => {
    var w: any = window;
    if (w.vssIsLoaded) {
      ReactDOM.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>,
        document.getElementById('root')
      );    
    } else {
      startIt();
    }
  }, 200);
}

startIt();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
