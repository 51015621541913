import * as React from 'react';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import LogsPanel from '../../components/LogsPanel/LogsPanel';
import CommandBarBeta from '../../components/PFS/CommandBarBeta/CommandBarBeta';
import { Spinner, SpinnerSize } from '@fluentui/react';

initializeIcons();

const PFSActionsBetaIntegration = () => {
    const [showLogsPanel, setShowLogsPanel] = React.useState<boolean>(false);
    const [logsPanelIsLoading, setLogsPanelIsLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (showLogsPanel) {
            setLogsPanelIsLoading(false);
        }
    }, [showLogsPanel]);

    const LoadSpinner = React.useMemo(() => {
        if (logsPanelIsLoading) {
            return <Spinner size={SpinnerSize.large} />;
        }
        return <></>;
    }, [logsPanelIsLoading]);

    return (
        <>
            <CommandBarBeta
                onShowLogClick={() => setShowLogsPanel(true)}
                onLoadLogsPanel={() => setLogsPanelIsLoading(true)}
            />
            { LoadSpinner }
            <LogsPanel
                isOpen={showLogsPanel}
                onDismiss={() => setShowLogsPanel(false)}
                onIncomingMessage={() => setShowLogsPanel(true)}
            />
        </>
    );
}

export default PFSActionsBetaIntegration;