export type Process = {
    id: string;
    name: string;
};

export type StepInfo = {
    nextStepId: string;
    onSelected: (data?: any) => Promise<Object>;
    label: string;
}

export type Step = {
    id: string;
    content?: string;
    contentRendererKey?: string;
    nextSteps?: StepInfo[];
    buttonAlignment?: 'horizontal' | 'vertical'
};

export interface PFSProcessesService {
    /**
     * Get all available processes
     */
    getProcesses(): Process[];

    /**
     * Gets all steps of a given process
     * First step on the array is always the starting step of the process
     * @throws if process does not exist
     * @param processId id of the given process
     */
    getProcessSteps(processId: string): Step[];
};

export const processStepMap: { [key: string]: string[] } = {
    'hotfix': [
        'hotfix-selectProjectName',
        'hotfix-createNewBranch',
        'hotfix-listExistingBranches',
        'hotfix-rcOrProd',
        'hotfix-testShowcase',
        'hotfix-testResult',
        'hotfix-prOrDeploy',
        'hotfix-prCompleted',
        'hotfix-deployFix'
    ]
}
