import React from 'react';
import * as _ from 'lodash';

import './GanttHeadRow.scss'

export const initialParentId = 33961;
//export const initialParentId = 34177;

export enum ViewMode {
    Customers,
    CurrSprint,
}


interface Properties {
    onParentIdChange: (parentId: number) => void;
    onViewModeChange: (viewMode: ViewMode) => void;
}

const GanttHeadRow = (props: Properties): JSX.Element => {
    const [topParentId, setTopParentId] = React.useState<number>(initialParentId);
    const [viewMode, setViewMode] = React.useState<ViewMode>(ViewMode.CurrSprint);

    const onParentIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const parentId = parseInt(value, 10);
        setTopParentId(parentId);
        props.onParentIdChange(parentId);
    }

    const onViewModeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        let view = ViewMode.Customers;
        switch(value) {
            case 'Customers': view = ViewMode.Customers; break;
            case 'Current Sprint': view = ViewMode.CurrSprint; break;
            default: break;
        }
        setViewMode(view);
        props.onViewModeChange(view);
    }

    const onParentIdChangeDebounce = _.debounce(onParentIdChange, 500, { leading: true });

    return (
        <div className="headrow">
            <div className="column left">
                <h1>Gantt</h1>
                <h2>- in construction -</h2>
            </div>
            <div className="column">
                <div className="right">
                    Parent Work item:
                    <input
                        type='number'
                        value={topParentId}
                        onChange={onParentIdChangeDebounce}
                    />
                    <br/>
                    View Mode:
                    <select
                        onChange={onViewModeChange}
                        defaultValue="Current Sprint"
                    >
                        <option>Customers</option>
                        <option>Current Sprint</option>
                    </select>
                </div>
            </div>
        </div>
    );
};

export default GanttHeadRow;
