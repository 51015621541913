import * as React from 'react';
import { EWorkItemType } from '../../../Enum';
import AdminEpic from './AdminEpic/AdminEpic';
import AdminUserStory from './AdminUserStory/AdminUserStory';
import AuthService from '../../../services/AuthService';

type Properties = {
    workItemType: EWorkItemType;
};

function checkAdminUser(): boolean {
    const adminEmails = [
        'v-jessicam@corp.groupxs.com',
        'tobias.hertkorn@hotmail.com',
        'nicolais@corp.groupxs.com',
        'dennis.sasse@gmail.com'
    ]

    return adminEmails.includes(AuthService.usermail);
}

const AdminInput = (props: Properties) => {
    const [isAdmin, setIsAdmin] = React.useState<boolean>(false);

    React.useEffect(() => {
        const userIsAdmin = checkAdminUser();
        setIsAdmin(userIsAdmin);
    }, []);

    const getAdminInputFields = () => {
        if (!isAdmin) {
            return <></>;
        }

        switch (props.workItemType) {
            case EWorkItemType.USER_STORY:
                return <AdminUserStory />;
            case EWorkItemType.EPIC:
                return <AdminEpic />;
            default:
                return <></>;
        }
    }

    return <> {getAdminInputFields()} </>;
};

export default AdminInput;
