import * as React from 'react';

const PFSActionsFallback = () => {
    return (
        <>
            <p data-testid="pfsActionsFallback-subheadline">This work item type has no actions defined.</p>
        </>
    )
}

export default PFSActionsFallback;