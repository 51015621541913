import * as _ from 'lodash';
import axios from 'axios';
import SocketService from './SocketService';
import VSSService from './VSSService';
import AuthService from './AuthService';
import { OperationResult } from '../types/types';

class PFSService {
    private async getCurrentWorkItem(): Promise<any> {
        const workItemId = await VSSService.getCurrentWorkItemId();
        return await VSSService.getWorkItemById(workItemId);
    }

    async getEnvironment(): Promise<{ usermail: string | undefined , pat: string | undefined , url: string| undefined } > {
        const windowUrl = document.referrer;
        const pattern = /https:\/\/(.*)\.visualstudio\.com/;

        let azureOrganization = '';
        const matches = windowUrl.match(pattern);
        if (matches && matches.length == 2) {
            azureOrganization = matches[1];
        }

        let usermail = process.env.REACT_APP_PROD_PFS_MAIL;
        let pat = process.env.REACT_APP_PROD_PFS_PAT;
        let url = process.env.REACT_APP_PROD_PFS_URL;
        if (azureOrganization == 'groupxs-test') {
            usermail = process.env.REACT_APP_DEV_PFS_MAIL;
            pat = process.env.REACT_APP_DEV_PFS_PAT;
            url = process.env.REACT_APP_DEV_PFS_URL;
        }

        return {
            usermail: usermail,
            pat: pat,
            url: url
        };
    }

    async postToEndpoint(endpoint: string, data?: any): Promise<OperationResult<any>> {
        const workItem = await this.getCurrentWorkItem();
        const envPFS = await this.getEnvironment();

        const usermail = AuthService.usermail;
        const pat = AuthService.pat;

        const defaultOptions: any = {
            usermail: usermail || envPFS.usermail,
            password: pat || envPFS.pat,
            data: {
                clickedBy: usermail,
                workItem: workItem,
                socketToken: SocketService.getToken(),
            }
        }

        let options = defaultOptions;
        if (data) {
            options.data = _.merge(defaultOptions.data, data);
        }

        try {
            const response = await axios.post(`${envPFS.url}${endpoint}`, options, {
                auth: {
                    username: process.env.REACT_APP_PFS_USER || '',
                    password: process.env.REACT_APP_PFS_PASS || ''
                }
            });

            if (response.status != 200) {
                return { ok: false, data: response.status };
            }
            return { ok: response.data.ok , data: response.data.data };
        } catch (error) {
            console.log(error);
            return { ok: false, data: error };
        }
    }

    async processPost(endpoint: string, stepId: string, stepData?: any): Promise<OperationResult<any>> {
        const envPFS = await this.getEnvironment();

        const usermail = AuthService.usermail;
        const pat = AuthService.pat;

        const options: any = {
            usermail: usermail || envPFS.usermail,
            password: pat || envPFS.pat,
            data: {
                clickedBy: usermail,
                socketToken: SocketService.getToken(),
                stepId: stepId,
                stepData: stepData
            }
        }

        try {
            const response = await axios.post(`${envPFS.url}processes${endpoint}`, options, {
                auth: {
                    username: process.env.REACT_APP_PFS_USER || '',
                    password: process.env.REACT_APP_PFS_PASS || ''
                }
            });

            if (response.status != 200) {
                return { ok: false, data: response.status };
            }

            return { ok: true, data: response.data };
        } catch (error) {
            console.log(error);
            return { ok: false, data: error };
        }
    }
}

const pfsService = new PFSService();
export default pfsService;