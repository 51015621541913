import React, { RefObject, useRef, useState } from 'react';
import Gantt, { Task } from 'frappe-gantt';

import { AdditionalTaskProps, createGanttTasks, fetchWorkItems, parseWorkItems } from './ganttLogic';
import { azureProps } from './azureProps';

import './GanttChart.scss';
import { ViewMode } from '../GanttHeadRow/GanttHeadRow';
interface Properties {
    topParentId: number;
    viewMode: ViewMode;
}

const GanttChart = (props: Properties): JSX.Element => {
    const svg = useRef<SVGSVGElement>() as RefObject<SVGSVGElement>;

    const [currViewMode, setCurrViewMode] = useState<ViewMode>(props.viewMode);

    let gantt: Gantt | null = null;
    let additionalTaskProps: Map<string, AdditionalTaskProps> | null;

    React.useEffect(() => {
        setCurrViewMode(props.viewMode);
    }, [props.viewMode]);

    React.useEffect(() => {
        drawGantt();
        VSS.notifyLoadSucceeded();
    }, [currViewMode])

    const fetchTasks = async (): Promise<Task[]> => {
        const foundWorkItems = await fetchWorkItems(props.topParentId, currViewMode);
        console.log('Fetched workitems:');
        console.log(foundWorkItems);

        const resolvedWorkItems = parseWorkItems(foundWorkItems, currViewMode);
        console.log('Resolved workitems:');
        console.log(resolvedWorkItems);

        const result = createGanttTasks(resolvedWorkItems, currViewMode);
        const resolvedTasks = result.ganttTasks;
        additionalTaskProps = result.additionalInfo;
        console.log('Created Gantt-tasks:');
        console.log(resolvedTasks);

        return resolvedTasks;
    };

    const drawGantt = async () => {
        console.log("Calling fetchWorkItems");

        const tasks = await fetchTasks();

        if (!!svg.current) {
            const ganttContainer = svg.current.parentElement;
            if(ganttContainer !== null && ganttContainer.className === 'gantt-container') {
                ganttContainer.replaceWith(svg.current);    //cleanup html (for rerendering)
            }

            if (gantt === null) {
                gantt = new Gantt(svg.current, tasks, {
                    on_click: (task) => { console.log(task) },
                    custom_popup_html: getCustomPopupHtml
                });
            }
            else {
               gantt.refresh(tasks);
            }
        }
        if(currViewMode === ViewMode.CurrSprint) {
            gantt?.change_view_mode('Quarter Day');
        } else {
            gantt?.change_view_mode('Week');
        }
    };

    const getCustomPopupHtml = (task: Gantt.EnrichedTask) => {
        let workitemType = '';
        let state = '';
        let startDate = '';
        let endDate = '';

        if (additionalTaskProps !== null) {
            const props = additionalTaskProps.get(task.id);
            if(props !== undefined) {
                workitemType = props.workItemType;
                state = props.status;
                startDate = props.realStartDate.toLocaleDateString() + ' ' + 
                    props.realStartDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit'});
                endDate = props.realEndDate.toLocaleDateString() + ' ' + 
                    props.realEndDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit'});
            }            
        }

        const witId = task.id.match(/\d{5,6}$/);
        if (witId === null) {
            console.error("Link to the workitem could not be resolved");
        }

        const url = `https://${azureProps.organisation}.visualstudio.com/${azureProps.project}/_workitems/edit/${witId}/`;

        return `
        <div class='title'>
            ${task.name}
        </div>
        <div class='subtitle'>
            <div class='workitem-type'>
                ${workitemType}
            </div>
            <div class='state'>
                State: ${state}
            </div>
            <div class='date'>
                ${startDate} - ${endDate}
            </div>
            <div class="button-container">
                <button type="button" class="workitem-button" onclick="window.open('${url}')">
                    Go to Work Item
                </button>
            </div>
        </div>
        `;
    };

    const renderContent = (): JSX.Element => {
        return (
            <div className="frame">
                <svg
                    ref={svg}
                    width="100%"
                    height="100%"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                />
            </div>
        )
    };

    return renderContent();
};

export default GanttChart;