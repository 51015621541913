import * as React from 'react';
import LogsPanel from '../../components/LogsPanel/LogsPanel';
import CommandBarCustomer from '../../components/PFS/CommandBarCustomer/CommandBarCustomer';
import { EWorkItemType } from '../../Enum';
import PFSActionsFallback from './PFSActionsFallback';

type Properties = {
    teamProject: string;
}

const reportheldProjects: string[] = ['com.reportheld.app', 'test.reportheld'];

const PFSActionsBug = (props: Properties) => {
    const [showLogsPanel, setShowLogsPanel] = React.useState<boolean>(false);

    const CommandBarContent = React.useMemo(() => {
        if (!reportheldProjects.includes(props.teamProject)) {
            return (
                <CommandBarCustomer 
                    workItemType={EWorkItemType.BUG} 
                    onLoadLogsPanel={() => {}}
                />
            );        }
        return <PFSActionsFallback />;
    }, [props.teamProject]);

    return (
        <>
            { CommandBarContent }
            <LogsPanel
                isOpen={showLogsPanel}
                onDismiss={() => setShowLogsPanel(false)}
                onIncomingMessage={() => setShowLogsPanel(true)}
            />
        </>
    );
}

export default PFSActionsBug;