import { EButton, EWorkItemState } from '../../../Enum';
import PFSService from '../../../services/PFSService';
import VSSService from '../../../services/VSSService';
import { ButtonsDisabled } from '../../../types/types';
import { Dispatch } from './CommandBarBeta.reducer';

export async function loadButtonsInitial(dispatch: Dispatch) {
    const buttonsDisabled = await calculateBetaButtonsDisabled();

    dispatch({
        type: 'loadButtons',
        disabledButtons: buttonsDisabled,
        hideDialog: true,
    });
}

export async function hideDialog(dispatch: Dispatch, hideDialog: boolean) {
    dispatch({
        type: 'hideDialog',
        hideDialog: hideDialog,
        isLoading: false
    });
}

export async function displayLoading(dispatch: Dispatch) {
    dispatch({
        type: 'displayLoading',
        isLoading: true
    });
}

export async function displayCreateDatabaseDump(dispatch: Dispatch) {
    dispatch({
        type: 'createDatabaseDump',
        buttonClicked: EButton.CREATE_DATABASE_DUMP,
        hideDialog: false,
        isLoading: false,
        dialogInfo: {
            endpoint: 'beta/createdatabasedump',
            title: 'Create Database Dump',
            subtext: 'This will create a new Database Dump work item and a fresh dump for this deployment.'
        }
    });
}

export async function displayForceDump(dispatch: Dispatch) {
    dispatch({
        type: 'forceDump',
        buttonClicked: EButton.CREATE_DATABASE_DUMP,
        hideDialog: false,
        isLoading: false,
        dialogInfo: {
            endpoint: 'beta/createdatabasedump',
            title: 'Force Database Dump',
            subtext: 'Automatic Database Dumps are disabled for this customer in the CIS. Would you like to force it?'
        }
    });
}

export async function displayCleanupCypressData(dispatch: Dispatch) {
    dispatch({
        type: 'cleanupCypressData',
        buttonClicked: EButton.CLEANUP_CYPRESS_DATA,
        hideDialog: false,
        isLoading: false,
        dialogInfo: {
            endpoint: 'beta/cleanupcypressdata',
            title: 'Clean up Cypress Data in the DB',
            subtext: `This action will remove all data related to Cypress from the Beta database.`
        }
    });
}

export async function displayRunCypressTests(dispatch: Dispatch) {
    dispatch({
        type: 'runCypressTests',
        buttonClicked: EButton.RUN_CYPRESS_TESTS,
        hideDialog: false,
        isLoading: false,
        dialogInfo: {
            endpoint: 'beta/runcypresstests',
            title: 'Run Cypress Tests',
            subtext: `This action will trigger the pipeline that runs the cypress tests for this customer.`
        }
    });
}

export async function displayRerunLastBuild(dispatch: Dispatch) {
    let title = 'Error';
    let subtext = `There is no build linked to this integration. Has this been deployed yet?`;
    let buildId = '' as any;

    const result = await PFSService.postToEndpoint("beta/rerunlastbuild");

    if (result.ok) {
        buildId = result.data.buildId;
        const buildResult = result.data.buildResult;

        title = 'Rerun Last Build';
        subtext = `The PFS will retrigger the previous ${buildResult} deployment Build ID ${buildId}.`;
    }

    dispatch({
        type: 'rerunLastBuild',
        buttonClicked: EButton.RERUN_LAST_BUILD,
        hideDialog: false,
        isLoading: false,
        dialogInfo: {
            endpoint: 'beta/rerunlastbuild',
            title: title,
            subtext: subtext
        },
        data: {
            buildId: buildId
        }
    });
}

async function calculateBetaButtonsDisabled(): Promise<ButtonsDisabled> {
    const betaIntegrationState = await VSSService.getCurrentWorkItemState();
    const disabled = betaIntegrationState != EWorkItemState.READY_FOR_TESTING;

    return {
        createDatabaseDump: disabled,
        runCypressTests: disabled,
        cleanupCypressData: disabled
    }
}