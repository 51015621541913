import * as React from 'react';
import { Panel, PanelType } from '@fluentui/react';
import SocketService from '../../services/SocketService';
import { ExtensionMessage } from '../../types/types';
import LogMessage from './LogMessage/LogMessage';

type Properties = {
    isOpen: boolean;
    onDismiss: () => void;
    onIncomingMessage: () => void;
};

const LogsPanel = (props: Properties) => {
    const [messages, setMessages] = React.useState<ExtensionMessage[]>([]);

    React.useEffect(() => {
        const id = SocketService.addListener(handleIncomingMessage);
        return () => SocketService.removeListener(id);
    }, []);

    React.useEffect(() => {
        console.log(messages);
    }, [messages, props.isOpen]);

    const handleIncomingMessage = (message: ExtensionMessage) => {
        props.onIncomingMessage();
        setMessages(preMsgs => [message, ...preMsgs]);
    }

    const logMessages = messages.map((message, index) => <LogMessage key={index} index={index} message={message}/> );

    return (
        <Panel
            isLightDismiss
            headerText="PFS Logs"
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            type={PanelType.medium}
            closeButtonAriaLabel="Close"
        >
            {logMessages}
        </Panel>
    );
}

export default LogsPanel;