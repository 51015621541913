import * as React from 'react';
import { IButtonProps, CommandBar, ICommandBarItemProps, Spinner, SpinnerSize } from '@fluentui/react';
import { EButton, EWorkItemType } from '../../../Enum';
import BetaDialog from './BetaDialog/BetaDialog';
import { dataStateReducer, defaultState } from './CommandBarBeta.reducer';
import { displayCleanupCypressData, displayCreateDatabaseDump, displayForceDump, displayLoading, displayRerunLastBuild, displayRunCypressTests, hideDialog, loadButtonsInitial } from './CommandBarBeta.actions';
import VSSService from '../../../services/VSSService';

type Properties = {
    onShowLogClick: () => void;
    onLoadLogsPanel: () => void;
}

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

const CommandBarBeta = (props: Properties) => {
    const [state, dispatch] = React.useReducer(dataStateReducer, defaultState());

    React.useEffect(() => {
        loadButtonsInitial(dispatch);

        VSSService.on(`${EWorkItemType.BETA_INTEGRATION}:refreshed`, () => {
            loadButtonsInitial(dispatch);
        });
    }, []);

    const items: ICommandBarItemProps[] = [
        {
            key: 'deployment',
            text: 'Deployment',
            iconProps: { iconName: 'Deploy' },
            subMenuProps: {
                items: [
                    {
                        key: EButton.CREATE_DATABASE_DUMP,
                        text: 'Create Database Dump',
                        iconProps: { iconName: 'Database' },
                        disabled: state.disabledButtons.createDatabaseDump,
                        onClick: () => {
                            displayLoading(dispatch);
                            displayCreateDatabaseDump(dispatch);
                        }
                    },
                    {
                        key: EButton.CLEANUP_CYPRESS_DATA,
                        text: 'Clean up Cypress Data',
                        iconProps: { iconName: 'DatabaseSync' },
                        disabled: state.disabledButtons.cleanupCypressData,
                        onClick: () => {
                            displayLoading(dispatch);
                            displayCleanupCypressData(dispatch);
                        }
                    },
                    {
                        key: EButton.RUN_CYPRESS_TESTS,
                        text: 'Run Cypress Tests',
                        iconProps: { iconName: 'TestPlan' },
                        disabled: state.disabledButtons.runCypressTests,
                        onClick: () => {
                            displayLoading(dispatch);
                            displayRunCypressTests(dispatch);
                        }
                    },
                    {
                        key: EButton.RERUN_LAST_BUILD,
                        text: 'Rerun Last Build',
                        iconProps: { iconName: 'Build' },
                        disabled: false,
                        onClick: () => {
                            displayLoading(dispatch);
                            displayRerunLastBuild(dispatch);
                        }
                    }
                ],
            },
        },
        {
            key: 'showLog',
            text: 'Show Log',
            iconProps: { iconName: 'ContextMenu' },
            onClick: props.onShowLogClick
        },
    ];

    const LoadSpinner = React.useMemo(() => {
        if (state.isLoading) {
            return (
                <div style={{ 'marginTop': '15rem' }}>
                    <Spinner size={SpinnerSize.large} />
                </div>
            );
        }
        return <></>;
    }, [state.isLoading]);

    return (
        <div>
            <CommandBar
                items={items}
                overflowButtonProps={overflowProps}
            />
            { LoadSpinner }
            <BetaDialog
                buttonClicked={state.buttonClicked}
                isHidden={state.hideDialog}
                onDismiss={() => hideDialog(dispatch, true)}
                onShow={() => hideDialog(dispatch, false)}
                onDisplayForceDump={() => displayForceDump(dispatch)}
                dialogInfo={state.dialogInfo}
                data={state.data}
                onLoadLogsPanel={props.onLoadLogsPanel}
            />
        </div>
    );
};

export default CommandBarBeta;