import { EButton } from '../../../Enum';
import { Dispatch } from './CommandBarUserStory.reducer';

export async function displayLoading(dispatch: Dispatch) {
    dispatch({
        type: 'displayLoading',
        isLoading: true
    });
}

export async function loadInitial(dispatch: Dispatch, usermail: string) {
    dispatch({
        type: 'loadInitial',
        hideDialog: true,
        isLoading: false,
    });
}

export async function createFollowup(dispatch: Dispatch) {
    dispatch({
        buttonClicked: EButton.CREATE_FOLLOWUP_US,
        type: 'createFollowup',
        hideDialog: false,
        isLoading: false,
        dialogInfo: {
            endpoint: 'userstory/createfollowup',
            title: 'Close & Create Follow-up',
            subtext: `This action will close the current User Story and recreate it in the next Sprint with its unfinished children Tasks.`
        },
    });
}

export async function cloneToCustomer(dispatch: Dispatch, projectName: string) {
    dispatch({
        type: 'cloneToCustomer',
        buttonClicked: EButton.CLONE_TO_CUSTOMER,
        hideDialog: false,
        isLoading: false,
        dialogInfo: {
            endpoint: 'userstory/clone',
            title: `'Clone to com.reportheld.app.${projectName}`,
            subtext: `This US will be cloned into the project com.reportheld.app.${projectName}.`
        }
    });
}

export async function hideDialog(dispatch: Dispatch, hideDialog: boolean) {
    dispatch({
        type: 'hideDialog',
        hideDialog: hideDialog,
        isLoading: false
    });
}
