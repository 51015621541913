import { PFSProcessesService, Step, StepInfo } from "../../contracts/PFSProcesses.contract";

/*
 * the list of nodes in our state graph. defines which component of the state
 * should be rendered
 */
type StateNode = 'IDLE' | 'LOADING';

export type State = {
    allSteps: Step[];
    stepsData: any;
    currentStepIds: string[];
    stateNode: StateNode;
};

export function defaultState(): State {
    return {
        allSteps: [],
        stepsData: {},
        currentStepIds: [],
        stateNode: 'IDLE'
    }
}

export type InitialLoadAction = {
    type: 'initialLoad';
    allSteps: Step[];
};

export type AddNewStepAction = {
    type: 'addNewStep';
    newStepsData: Object;
    newStepId: string;
};

export type Action = InitialLoadAction | AddNewStepAction;
export type Dispatch = (action: Action) => void;

export function dataStateReducer(state: State, action: Action): State {
    switch (action.type) {
        case 'initialLoad': {
            return {
                stateNode: 'IDLE',
                allSteps: action.allSteps,
                currentStepIds: [action.allSteps[0].id],
                stepsData: {}
            }
        }
        case 'addNewStep': {
            return {
                ...state,
                stateNode: 'IDLE',
                currentStepIds: [...state.currentStepIds, action.newStepId],
                stepsData: { ...state.stepsData, ...action.newStepsData }
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${(action as any).type}`);
        }
    }
}

export function loadInitial(dispatch: Dispatch, pfsProcessesService: PFSProcessesService, processId: string) {
    // fetch process steps from service
    const steps = pfsProcessesService.getProcessSteps(processId);
    dispatch({ type: 'initialLoad', allSteps: steps });
}

export async function addNewStep(dispatch: Dispatch, nextStepInfo: StepInfo) {
    const newStepsData = await nextStepInfo.onSelected();

    dispatch({
        type: 'addNewStep',
        newStepId: nextStepInfo.nextStepId,
        newStepsData
    });
}
