import { Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react';
import * as React from 'react';
import { Step, StepInfo } from '../../contracts/PFSProcesses.contract';
import PFSService from '../../services/PFSService';

type Properties = {
    step: Step;
    addNewStep: (nextStepInfo: StepInfo, projectName: string) => void
}

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 300 },
    label: { fontSize: "large" }
};

async function requestProjectNames(): Promise<string[]> {
    const postResult = await PFSService.processPost('/hotfix/selectprojectname', 'hotfix-selectProjectName');
    if (!postResult.ok) {
        return [];
    }
    return postResult.data.projectNames;
}

const CustomerDropdownStep = (props: Properties) => {
    const [projectNames, setProjectNames] = React.useState<string[]>([]);
    const [selectedItem, setSelectedItem] = React.useState<IDropdownOption>();

    React.useEffect(() => {
        const getProjectNames = async () => {
            const requestedProjectNames = await requestProjectNames();
            setProjectNames(requestedProjectNames);
        }
        getProjectNames();
    }, []);

    const projectNameOptions = React.useMemo<IDropdownOption[]>(() => {
        return projectNames.map((projectName, index) => ({ key: index, text: projectName }));
    }, [projectNames]);

    const onChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
        setSelectedItem(item);

        if (!item?.text || props.step.nextSteps?.length != 1) {
            return;
        }
        const nextStep = props.step.nextSteps[0];
        props.addNewStep(nextStep, item.text);
    };

    return (
        <div style={{ margin: "1rem" }}>
            <Dropdown
                selectedKey={selectedItem ? selectedItem.key : undefined}
                placeholder='Project Name'
                label='Select the customer:'
                options={projectNameOptions}
                styles={dropdownStyles}
                onChange={onChange}
            />
        </div>
    );
}

export default CustomerDropdownStep;