import * as React from 'react';
import * as _ from 'lodash';
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, TextField } from '@fluentui/react';
import { EButton } from '../../../../Enum';
import PFSService from '../../../../services/PFSService';

type Properties = {
    buttonClicked: EButton;
    isHidden: boolean;
    dialogInfo: {
        endpoint: string;
        title: string;
        subtext: string;
    };
    onDismiss: () => void;
    onShow: () => void;
    onLoadLogsPanel: () => void;
};

const UserStoryDialog = (props: Properties) => {
    const [data, setData] = React.useState<{[key: string]: any}>();
    const [storyPointsIsEmpty, setStoryPoinsIsEmpty] = React.useState<boolean>(true);

    const onConfirmClick = () => {
        const handlePostToEndpoint = async () => {
            const result = await PFSService.postToEndpoint(props.dialogInfo.endpoint, data);
        }
        handlePostToEndpoint();
        props.onDismiss();
        props.onLoadLogsPanel();
    };

    const onCancelClick = () => {
        props.onDismiss();
        setData(undefined);
    }

    const onStoryPointsAdded = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
        if (newValue && newValue != '0') {
            setStoryPoinsIsEmpty(false);
            setData({storyPoints: newValue})
        }
    }

    const getDialogContent = () => {
        if (props.buttonClicked == EButton.CLONE_TO_CUSTOMER) {
            return <></>;
        }

        if (storyPointsIsEmpty && props.buttonClicked == EButton.CREATE_FOLLOWUP_US) {
            return (
                <TextField
                    label='Story Points'
                    onChange={onStoryPointsAdded}
                    errorMessage={'Please estimate the follow up Story Points. This field cannot be empty.'}
                />
            );
        }

        return <TextField label='Followup Story Points' onChange={onStoryPointsAdded} />;
    };

    const getDialogFooterContent = () => {
        return (
            <DialogFooter>
                <PrimaryButton
                    onClick={onConfirmClick}
                    text="OK" />
                <DefaultButton
                    onClick={onCancelClick}
                    text="Cancel" />
            </DialogFooter>
        );
    };

    return (
        <Dialog
            hidden={props.isHidden}
            onDismiss={props.onDismiss}
            dialogContentProps={{
                type: DialogType.normal,
                title: props.dialogInfo.title,
                closeButtonAriaLabel: 'Close',
                subText: props.dialogInfo.subtext,
            }}
        >
            { getDialogContent() }
            { getDialogFooterContent() }
        </Dialog>
    );
}

export default UserStoryDialog;