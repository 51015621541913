import * as React from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';
import LogsPanel from '../../components/LogsPanel/LogsPanel';
import CommandBarCustomer from '../../components/PFS/CommandBarCustomer/CommandBarCustomer';
import { EWorkItemType } from '../../Enum';
import PFSActionsFallback from './PFSActionsFallback';

type Properties = {
    teamProject: string;
}

const reportheldProjects: string[] = ['com.reportheld.app', 'test.reportheld'];

const PFSActionsFeature = (props: Properties) => {
    const [showLogsPanel, setShowLogsPanel] = React.useState<boolean>(false);
    const [logsPanelIsLoading, setLogsPanelIsLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (showLogsPanel) {
            setLogsPanelIsLoading(false);
        }
    }, [showLogsPanel]);

    const CommandBarContent = React.useMemo(() => {
        if (!reportheldProjects.includes(props.teamProject)) {
            return (
                <CommandBarCustomer
                    workItemType={EWorkItemType.FEATURE}
                    onLoadLogsPanel={() => setLogsPanelIsLoading(true)}
                />
            );
        }
        return <PFSActionsFallback />;
    }, [props.teamProject]);

    const LoadSpinner = React.useMemo(() => {
        if (logsPanelIsLoading) {
            return (
                <div style={{ 'marginTop': '15rem' }}>
                    <Spinner size={SpinnerSize.large} />
                </div>
            );
        }
        return <></>;
    }, [logsPanelIsLoading]);

    return (
        <>
            { CommandBarContent }
            { LoadSpinner }
            <LogsPanel
                isOpen={showLogsPanel}
                onDismiss={() => setShowLogsPanel(false)}
                onIncomingMessage={() => setShowLogsPanel(true)}
            />
        </>
    );
}

export default PFSActionsFeature;