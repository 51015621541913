import * as React from 'react';
import { IButtonProps, CommandBar, ICommandBarItemProps } from '@fluentui/react';

type Properties = {
    onShowLogClick: () => void
}

const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

const CommandBarRc = (props: Properties) => {
    const items: ICommandBarItemProps[] = [
        {
            key: 'showLog',
            text: 'Show Log',
            iconProps: { iconName: 'ContextMenu' },
            onClick: props.onShowLogClick
        },
    ];

    return (
        <div>
            <CommandBar
                items={items}
                overflowButtonProps={overflowProps}
            />
        </div>
    );
};

export default CommandBarRc;