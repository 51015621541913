import { Dropdown, IDropdownOption, IDropdownStyles, ITextFieldStyles, Label, PrimaryButton, Separator, Stack, TextField } from '@fluentui/react';
import * as React from 'react';
import PFSService from '../../../../services/PFSService';
import VSSService from '../../../../services/VSSService';

const textFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: "50%" } };

const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: '50%' } };

const options: IDropdownOption[] = [
    { key: 'xs', text: 'XS' },
    { key: 's', text: 'S' },
    { key: 'm', text: 'M' },
    { key: 'l', text: 'L' },
    { key: 'xl', text: 'XL' },
    { key: 'xxl', text: 'XXL' }
];

const PFSAdminUserStory = () => {
    const [adminFields, setAdminFields] = React.useState<{ estimated: string, tshirtSize: string }>({ estimated: '', tshirtSize: '' });
    const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(true);
    const [errorMessage, setErrorMessage] = React.useState<string>('');

    React.useEffect(() => {
        const getCurrentFieldValue = async () => {
            const estimatedValue = await VSSService.getCurrentWorkItemFieldValue("Custom.Estimated");
            const tshirtSizeValue = await VSSService.getCurrentWorkItemFieldValue("Custom.TShirtSize");

            setAdminFields({
                estimated: estimatedValue,
                tshirtSize: tshirtSizeValue
            });
        }
        getCurrentFieldValue();
    }, []);

    const handleEstimatedChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setButtonDisabled(false);
        if (!newValue) {
            return;
        }
        setAdminFields({ ...adminFields, estimated: newValue });
    }

    const handleTshirtSizeChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        setButtonDisabled(false);
        if (!option) {
            return;
        }
        setAdminFields({ ...adminFields, tshirtSize: option.text });
    }

    const handleButtonClick = async () => {
        setButtonDisabled(true);

        const response = await PFSService.postToEndpoint('userstory/setadminfields', { adminFields: adminFields })
        if (!response.ok) {
            setErrorMessage('Unable to update field. Please refresh and try again.');
        }
    }

    return (
        <>
            <div style={{ height: "60px"}}></div>
            <Label style={{ fontSize: "12pt"}}>Admin View</Label>
            <Separator style={{ fontWeight: "bold"}} />
            <Stack tokens={{ childrenGap: 10 }}>
                <TextField 
                    label="Estimated"
                    onChange={handleEstimatedChange}
                    value={adminFields.estimated}
                    styles={textFieldStyles}
                    errorMessage={errorMessage}
                />
                <Dropdown
                    placeholder={adminFields.tshirtSize}
                    onChange={handleTshirtSizeChange}
                    label="TShirt Size"
                    options={options}
                    styles={dropdownStyles}
                    errorMessage={errorMessage}
                />
                <PrimaryButton 
                    text="Update"
                    onClick={handleButtonClick}
                    disabled={buttonDisabled}
                    style={{ width: '20%' }}
                />
            </Stack>
        </>
    );
};

export default PFSAdminUserStory;