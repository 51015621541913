import { EButton } from "../../../Enum";

type DialogInfo = {
    endpoint: string;
    title: string;
    subtext: string;
}

export type State = {
    buttonClicked: EButton;
    hideDialog: boolean;
    isLoading: boolean;
    dialogInfo: DialogInfo;
};

export function defaultState(): State {
    return {
        buttonClicked: EButton.NONE,
        hideDialog: true,
        isLoading: false,
        dialogInfo: {
            endpoint: '',
            title: '',
            subtext: ''
        }
    }
}

export type HideDialog = {
    type: 'hideDialog';
    hideDialog: boolean;
    isLoading: boolean;
};

export type DisplayLoading = {
    type: 'displayLoading';
    isLoading: boolean;
}

export type LoadInitial = {
    type: 'loadInitial';
    hideDialog: boolean;
    isLoading: boolean;
};

export type CreateFollowup = {
    type: 'createFollowup';
    buttonClicked: EButton;
    hideDialog: boolean;
    isLoading: boolean;
    dialogInfo: DialogInfo;
};

export type CloneToCustomer = {
    type: 'cloneToCustomer';
    buttonClicked: EButton;
    hideDialog: boolean;
    isLoading: boolean;
    dialogInfo: DialogInfo;
};

export type Action = HideDialog | DisplayLoading | LoadInitial | CreateFollowup | CloneToCustomer;

export function dataStateReducer(state: State, action: Action): State {
    switch (action.type) {
        case 'hideDialog': {
            return {
                ...state,
                hideDialog: action.hideDialog,
                isLoading: action.isLoading
            }
        }
        case 'displayLoading': {
            return {
                ...state,
                isLoading: action.isLoading
            }
        }
        case 'loadInitial': {
            return {
                ...state,
                hideDialog: action.hideDialog,
                isLoading: action.isLoading
            }
        }
        case 'createFollowup': {
            return {
                ...state,
                buttonClicked: action.buttonClicked,
                hideDialog: action.hideDialog,
                isLoading: action.isLoading,
                dialogInfo: action.dialogInfo
            }
        }
        case 'cloneToCustomer': {
            return {
                ...state,
                buttonClicked: action.buttonClicked,
                hideDialog: action.hideDialog,
                isLoading: action.isLoading,
                dialogInfo: action.dialogInfo
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${(action as any).type}`);
        }
    }
}

export type Dispatch = (action: Action) => void;
