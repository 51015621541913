import * as React from 'react';
import { EWorkItemType } from '../../../Enum';
import AdminInput from '../AdminInput/AdminInput';

const CommandBarEpic = () => {
    return (
        <AdminInput 
            workItemType={EWorkItemType.EPIC}
        />
    );
};

export default CommandBarEpic;
