import { Task } from 'frappe-gantt';
import { WorkItem, WorkItemLink, WorkItemQueryResult, WorkItemUpdate } from 'TFS/WorkItemTracking/Contracts';
import { WorkItemTrackingHttpClient } from 'TFS/WorkItemTracking/RestClient';
import { ViewMode } from '../GanttHeadRow/GanttHeadRow';
import { azureProps } from './azureProps';

type activatedClosedDatesType = {
    activated: Date | undefined,
    closed: Date | undefined
};

type workTask = {
    id: number,
    url: string,
    workItemType: string,
    title: string,
    effort: number,
    targetDate: string,
    startDate: string,
    closedDate: string,
    completedWork: number,
    state: string,
    parentUrl: string,
    childrenUrls: string[],
    progress: number
}

class MyTask {
    id: string;
    name: string;
    start: Date;
    end: Date | undefined;
    progress: number;
    customClass: string;
    dependencies: string[];

    constructor(
        id: string,
        name: string,
        start: string | Date,
        end: string | Date | undefined,
        progress: number,
        customClass?: string,
        dependencies?: string[]
    ) {
        this.id = id;
        this.name = name;
        this.start = start === undefined ? new Date() : new Date(start);
        this.end = end === undefined ? undefined : new Date(end);
        this.progress = progress;
        this.customClass = customClass || '';
        this.dependencies = dependencies || [];
    }

    createGanttTask(): Task {
        let dependenciesString = '';
        for (const dep of this.dependencies) {
            dependenciesString += dep + ', ';
        }


        let shiftedStart = new Date(this.start);
        shiftedStart.setHours(this.start.getHours() - 12 - (this.start.getTimezoneOffset())/60);

        let shiftedEnd = new Date();
        if(this.end !== undefined) {
            shiftedEnd = new Date(this.end);
            shiftedEnd.setHours(this.end.getHours() - 12 - (this.start.getTimezoneOffset())/60);
        }

        const start = dateToGanttDateString(shiftedStart);
        const end = dateToGanttDateString(shiftedEnd);
        return {
            id: this.id,
            name: this.name,
            start: start,
            end: end,
            progress: this.progress,
            dependencies: dependenciesString,
            custom_class: this.customClass
        };
    }
}

export const fetchWorkItems = async (topLevelParentId: number, viewMode: ViewMode): Promise<WorkItem[]> => {
    const workItems = new Promise<WorkItem[]>(resolve => {
        VSS.require(["VSS/Service", "TFS/WorkItemTracking/RestClient"], async function (VSS_Service: any, TFS_Wit_WebApi: any) {
            //get the REST client
            let witClient: WorkItemTrackingHttpClient =
                await VSS_Service.getCollectionClient(TFS_Wit_WebApi.WorkItemTrackingHttpClient);

            let query = '';
            switch (viewMode) {
                case ViewMode.CurrSprint:
                    query = `SELECT [System.Id] 
                    FROM WorkItemLinks 
                    WHERE [Source].[System.WorkItemType] = 'Release'
                        AND [Source].[System.Title] Contains 'Sprint' 
                        AND [Source].[System.CreatedDate] > @today - 10
                        AND [Source].[System.State] = 'Active'
                        AND ([System.Links.LinkType] = 'Child') 
                        AND ([Target].[System.State] <> 'Removed') 
                        AND ([Target].[System.WorkItemType] = 'User Story' OR [Target].[System.WorkItemType] = 'Bug')
                    MODE (Recursive)`;
                    break;
                case ViewMode.Customers:
                    query = `SELECT [System.Id] 
                    FROM WorkItemLinks 
                    WHERE ([Source].[System.WorkItemType] = 'Customer') 
                        AND ([System.Links.LinkType] = 'Child') 
                        AND ([Target].[System.State] <> 'Removed') 
                    MODE (Recursive)`;
                    break;
                default: break;
            }


            let workItemQueryResult: WorkItemQueryResult = await witClient.queryByWiql(
                { query },
                azureProps.project);

            let witIds = getWitIds(workItemQueryResult.workItemRelations);

            let workItems = await witClient.getWorkItems(witIds, undefined, undefined, 1);  //1 for 'Relations', enum-import does not work
            //console.log(workItems);

            resolve(workItems);
        });
    });
    return workItems;
}

export const parseWorkItems = (workItems: WorkItem[], topLevelParentId: number): workTask[] => {
    let resolvedTasks: workTask[] = [];

    for (const workItem of workItems) {
        let id = workItem.id;
        let url = workItem.url;
        //let url = `https://${azureProps.organisation}.visualstudio.com/${azureProps.project}/_workitems/edit/${id}/`
        let title = '';
        let effort = 1;
        let createdDate = null;
        let startDate = null;
        let activatedDate = null;
        let targetDate = null;
        let closedDate = null;
        let completedWork = 0;
        let state = 'New';
        let workItemType = 'Task';

        // let updates = await witClient.getUpdates(id);
        // let activatedClosedDates = GanttLogic.parseUpdates(updates);

        let activatedClosedDates: activatedClosedDatesType = { activated: undefined, closed: undefined }

        if (workItem.fields) {
            title = workItem.fields["System.Title"];
            effort = workItem.fields["Microsoft.VSTS.Scheduling.Effort"];
            // if the currentNode is done we should really look at the close date, etc.
            createdDate = workItem.fields["System.CreatedDate"];
            startDate = workItem.fields["Microsoft.VSTS.Scheduling.StartDate"];
            activatedDate = workItem.fields["Microsoft.VSTS.Common.ActivatedDate"];
            targetDate = workItem.fields["Microsoft.VSTS.Scheduling.TargetDate"];
            closedDate = workItem.fields["Microsoft.VSTS.Common.ClosedDate"];

            completedWork = workItem.fields["Microsoft.VSTS.Scheduling.CompletedWork"];
            state = workItem.fields["System.State"];
            workItemType = workItem.fields["System.WorkItemType"];
            if (targetDate) {
                targetDate = new Date(targetDate);
            }
            if (closedDate) {
                closedDate = new Date(closedDate);
            }
            if (activatedClosedDates.closed) {
                closedDate = activatedClosedDates.closed;
            }
            if (closedDate) {
                targetDate = closedDate;
            }
            if (startDate) {
                startDate = new Date(startDate);
            }
            if (activatedDate) {
                activatedDate = new Date(activatedDate);
            }
            if (activatedClosedDates.activated) {
                activatedDate = activatedClosedDates.activated;
            }
            if (activatedDate) {
                startDate = activatedDate;
            }
            if (!startDate) {
                startDate = createdDate;
            }
            startDate.toString().replace('T', ' ');
        }

        let parent = '';
        let childrenUrls: string[] = [];
        if (workItem.relations !== undefined) {
            for (const relation of workItem.relations) {
                if (relation) {
                    if (relation.rel === "System.LinkTypes.Hierarchy-Reverse" && relation.attributes && relation.attributes.name === "Parent" && id !== topLevelParentId) {
                        parent = relation.url;
                    }
                    if (relation.rel === "System.LinkTypes.Hierarchy-Forward" && relation.attributes && relation.attributes.name === "Child") {
                        childrenUrls.push(relation.url);
                    }
                }
            }
        }

        let progress = 0;
        if (state === "Closed" || state === "Resolved") {
            progress = 100;
        } else {
            // calculate percentage
            if (effort > 0 && completedWork > 0) {
                progress = 100.0 * completedWork / effort;
                progress = Math.round(progress);
                if (progress > 100) {
                    progress = 100;
                }
            }
        }

        if (state !== "Removed") {
            resolvedTasks.push({
                id,
                url,
                workItemType,
                title,
                effort,
                targetDate,
                startDate,
                closedDate,
                completedWork,
                state,
                parentUrl: parent,
                childrenUrls,
                progress
            });
        }
    }

    return resolvedTasks;
};

export interface AdditionalTaskProps {
    workItemType: string;
    status: string;
    realStartDate: Date;
    realEndDate: Date;
}

interface TaskNode {
    task: MyTask;
    childs: TaskNode[];
}

let taskIdToIndexMap = new Map<string, number>();



export const createGanttTasks = (tasks: workTask[], viewMode: ViewMode) => {
    let res;
    if (viewMode === ViewMode.Customers) {
        res = createTasksCustomer(tasks);
    }
    else {
        res = createTasksSprint(tasks);
    }

    let ganttTasks: Task[] = [];
    for (const myTask of res.myTasks) {
        ganttTasks.push(myTask.createGanttTask());
    }
    return { ganttTasks, additionalInfo: res.additionalInfo }
};

const createTasksCustomer = (workTasks: workTask[]): { myTasks: MyTask[], additionalInfo: Map<string, AdditionalTaskProps> } => {
    let allTasks: MyTask[] = [];
    let additionalInfo = new Map<string, AdditionalTaskProps>();

    let nodes: TaskNode[] = [];
    let index = 0;

    for (const workTask of workTasks) {
        let task = parseWorkTask(workTask);
        task.dependencies = [workTask.parentUrl];

        allTasks.push(task);
        
        //here are the rules applied
        if (task.end == undefined) {
            let startDate = new Date(task.start);
            startDate.setDate(startDate.getDate() + 7)
            task.end = startDate;
        }
        
        additionalInfo.set(task.id, { 
            workItemType: workTask.workItemType, 
            status: workTask.state,
            realStartDate: task.start,
            realEndDate: task.end
        });
        nodes.push({ task: task, childs: [] });

        taskIdToIndexMap.set(task.id, index);
        index++;
    }

    return { myTasks: allTasks, additionalInfo };
}


const createTasksSprint = (workTasks: workTask[]): { myTasks: MyTask[], additionalInfo: Map<string, AdditionalTaskProps> } => {
    let allTasks: MyTask[] = [];
    let additionalInfo = new Map<string, AdditionalTaskProps>();

    if (workTasks.length <= 0) {
        return { myTasks: allTasks, additionalInfo };
    }

    const rootTask = parseWorkTask(workTasks[0]);
    if (rootTask.end === undefined) {
        let startDate = new Date(rootTask.start);
        startDate.setDate(startDate.getDate() + 4);
        rootTask.end = startDate;
    }
    additionalInfo.set(rootTask.id, { 
        workItemType: workTasks[0].workItemType,
        status: workTasks[0].state,
        realStartDate: rootTask.start,
        realEndDate: rootTask.end
    })
    allTasks.push(rootTask);

    let userStories: MyTask[] = [];
    let bugs: MyTask[] = [];

    let nextDate = new Date();
    const bugsTask = new MyTask(
        'bugs-virtual',
        'Bugs',
        rootTask.start,
        dateToGanttDateString(nextDate),
        0,
        'bug'
    );
    bugsTask.dependencies = [rootTask.id];
    bugs.push(bugsTask);

    for (let i = 1; i < workTasks.length; i++) {
        const ganttTask = parseWorkTask(workTasks[i]);
        
        if (workTasks[i].workItemType === 'Bug') {
            ganttTask.dependencies = ['bugs-virtual'];
            
            if (ganttTask.end === undefined) {
                ganttTask.start = new Date(nextDate);
                nextDate.setHours(nextDate.getHours() + 2);
                ganttTask.end = new Date(nextDate);
            }
            
            bugs.push(ganttTask);
        } else {
            ganttTask.dependencies = [workTasks[i].parentUrl];
            userStories.push(ganttTask);
            if (ganttTask.end === undefined) {
                let startDate = new Date(ganttTask.start);
                startDate.setDate(startDate.getDate() + 4);
                ganttTask.end = startDate;
            }    
        }
        additionalInfo.set(ganttTask.id, { 
            workItemType: workTasks[i].workItemType,
            status: workTasks[i].state,
            realStartDate: ganttTask.start,
            realEndDate: ganttTask.end
        })
    }
    bugsTask.end = nextDate;
    additionalInfo.set('bugs-virtual', {
        workItemType: 'Virtual Node',
        status: '',
        realStartDate: bugsTask.start,
        realEndDate: bugsTask.end
    })

    allTasks.push(...bugs);
    allTasks.push(...userStories);

    let nodes: TaskNode[] = [];
    let index = 0;
    for (const task of allTasks) {
        nodes.push({ task: task, childs: [] });

        taskIdToIndexMap.set(task.id, index);
        index++;
    }

    return { myTasks: allTasks, additionalInfo };
}


const parseWorkTask = (workTask: workTask): MyTask => {
    const startDate = dateToGanttDateString(new Date(workTask.startDate));
    // let endDate: any = workTask.targetDate;
    // if (workTask.targetDate !== undefined) {
    //     endDate = dateToGanttDateString(new Date(workTask.targetDate));
    // }

    let ganttTask = new MyTask(
        workTask.url,
        workTask.title + " - " + workTask.id,
        startDate,
        workTask.targetDate,
        workTask.progress
    );

    ganttTask.customClass = workTask.workItemType ? workTask.workItemType.toLowerCase().replace(' ', '-') : '';

    return ganttTask;
};


const buildTaskTree = (initialNodes: TaskNode[]): TaskNode => {
    const root = initialNodes[0];
    for (let node of initialNodes) {
        for (let dependence of node.task.dependencies) {
            const index = taskIdToIndexMap.get(dependence);
            if (index !== undefined) {
                initialNodes[index].childs.push(node);
            }
        }
    }
    return root;
}


const printTree = (tree: TaskNode) => {
    console.log(tree.task.name);
    for (const child of tree.childs) {
        printTree(child);
    }
}

const calculateProgress = (tree: TaskNode) => {
    if (tree.childs.length === 0) {
        return;
    }
    let childsProgress = 0;
    for (const child of tree.childs) {
        calculateProgress(child);
        childsProgress += child.task.progress;
    }
    childsProgress /= tree.childs.length;
    childsProgress /= 2;
    tree.task.progress = tree.task.progress / 2 + childsProgress;
}

// const calculateTaskEnds = (tree: TaskNode) => {
//     if (tree.childs.length === 0) {
//         return;
//     }
//     let latestChildEndDate = undefined;
//     for (const child of tree.childs) {
//         calculateTaskEnds(child);
//         const childDate = new Date(child.task.end);
//         if (latestChildEndDate === undefined || latestChildEndDate < childDate) {
//             latestChildEndDate = childDate;
//         }
//     }
//     let myDate = new Date(tree.task.end);
//     if (latestChildEndDate !== undefined && myDate.toDateString() === latestChildEndDate.toDateString() && myDate >= new Date()) {
//         myDate.setDate(myDate.getDate() + 1);
//         tree.task.end = myDate;
//     }
// }

const getWitIds = (links: WorkItemLink[]): Array<number> => {
    let workItemIds: Map<number, boolean> = new Map();
    for (const workItemRef of links) {
        if (workItemRef.source) {
            workItemIds.set(workItemRef.source.id, true);
        }
        if (workItemRef.target) {
            workItemIds.set(workItemRef.target.id, true);
        }
    }
    //console.log(workItemIds);
    return Array.from(workItemIds.keys());
};

const dateToGanttDateString = (date: Date): string => date.toISOString().replace('T', ' ');

// export const testTasks = (): Task[] => {
//     let testTask0 = new Task({
//         id: "Task0",
//         name: "Parent",
//         start: "2020-11-17",
//         end: "2020-12-02",
//         progress: 10
//     });
//     let testTask1 = new Task({
//         id: "Task1",
//         name: "Child 1",
//         start: "2020-11-20",
//         end: "2020-12-02",
//         progress: 0
//     });
//     let testTask2 = new Task({
//         id: "Task2",
//         name: "Child 2",
//         start: "2020-11-25",
//         end: "2020-12-02",
//         progress: 0
//     });
//     let testTask3 = new Task({
//         id: "Task3",
//         name: "Child 3",
//         start: "2020-11-30",
//         end: "2020-12-02",
//         progress: 0
//     });
//     testTask1.setDependencies(["Task0"]);
//     testTask2.setDependencies(["Task0"]);
//     testTask3.setDependencies(["Task0"]);

//     let tasks = [testTask0, testTask1, testTask2, testTask3];

//     return tasks;
// }

// function parseUpdates(updates: WorkItemUpdate[]): activatedClosedDatesType {
//     // Integrations - active: new -> staged, done: -> closed
//     // Releases - active: new -> staged, done: -> closed
//     // everything - active: -> active, done: -> resolved/ -> closed
//     let activatedClosedDates: activatedClosedDatesType = {
//         activated: undefined,
//         closed: undefined
//     };

//     let lastGoodDate = new Date();

//     for (const update of updates) {
//         // console.log(update.revisedDate, update.revisedDate < new Date("9900-01-01"));
//         if (update.revisedDate < new Date("9900-01-01")) {
//             lastGoodDate = update.revisedDate;
//         }

//         if (update && update.fields && update.fields["System.State"] && update.fields["System.State"].newValue && update.fields["System.State"].oldValue) {
//             if (update.fields["System.State"].newValue === "Resolved" || update.fields["System.State"].newValue === "Closed") {
//                 //if (lastGoodDate > activatedClosedDates.closed) {
//                 activatedClosedDates.closed = lastGoodDate;
//                 //}
//             }
//             if ((!activatedClosedDates.activated && update.fields["System.State"].newValue === "Staged") || update.fields["System.State"].newValue === "Active") {
//                 activatedClosedDates.activated = lastGoodDate;
//             }
//         }
//     }

//     if (!activatedClosedDates.activated) {
//         // search again with looser requirements
//         for (const update of updates) {
//             // console.log(update.revisedDate, update.revisedDate < new Date("9900-01-01"));
//             if (update.revisedDate < new Date("9900-01-01")) {
//                 lastGoodDate = update.revisedDate;
//             }

//             if (update && update.fields && update.fields["System.State"] && update.fields["System.State"].newValue && update.fields["System.State"].oldValue) {
//                 if ((!activatedClosedDates.activated && update.fields["System.State"].newValue === "Triaged") || update.fields["System.State"].newValue === "Staged") {
//                     activatedClosedDates.activated = lastGoodDate;
//                 }
//             }
//         }
//     }

//     // TODO: Only do this if we are "past" Active
//     if (!activatedClosedDates.activated) {
//         // just take the first
//         activatedClosedDates.activated = updates[0].revisedDate;
//     }

//     // TODO: Remove these dates again, if later it transitioned back to an "New" or Not-"Closed" state
//     return activatedClosedDates;
// }