import * as React from 'react';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import LogsPanel from '../../components/LogsPanel/LogsPanel';
import CommandBarBeta from '../../components/PFS/CommandBarBeta/CommandBarBeta';
import {
    Spinner, SpinnerSize, Dialog,
    DialogFooter,
    DialogType,
    PrimaryButton,
    DefaultButton
} from '@fluentui/react';
import VSSService from '../../services/VSSService';
import axios from 'axios';
import PFSService from '../../services/PFSService';
import { opendir } from 'fs';
import { EWorkItemState, EWorkItemType } from '../../Enum';
import { useRef } from 'react';
import { update } from 'lodash';
import vssservice from '../../services/VSSService';

initializeIcons();

const organization = "groupxs-test";
const project = "test.reportheld";
const url = `https://dev.azure.com/${organization}/${project}/PFS/_apis/wit/wiql?api-version=7.1`;
const customer = 'testautomation';
const beta = EWorkItemType.BETA_INTEGRATION;
const rc = EWorkItemType.RC_INTEGRATION;

const PFSActionsDeploy = () => {
    const [lockedIds, setLockedIds] = React.useState<number[]>([]);
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);

    const onConfirmClicked = () => {
        const body = { deployConfirmed: true, deployCanceled: false, resource: { workItemId: workItem.current?.id, fields: { 'System.State': { oldValue: 'New', newValue: 'Staged' } }, revision: { fields: { 'System.ChangedBy': { oldValue: 'vso-groupxs-hindelang <vso-groupxs-hindelang@groupxs.com>', newValue: 'Henrique Knopki <v-henriquek@groupxs.com>' } } } } };
        PFSService.postToEndpoint(`workitemupdated`, { req: body });
        setOpenDialog(false);
    };

    const onCancelClicked = () => {
        const body = { deployConfirmed: false, deployCanceled: true, resource: { workItemId: workItem.current?.id, fields: { 'System.State': { oldValue: 'New', newValue: 'Staged' } }, revision: { fields: { 'System.ChangedBy': { oldValue: 'vso-groupxs-hindelang <vso-groupxs-hindelang@groupxs.com>', newValue: 'Henrique Knopki <v-henriquek@groupxs.com>' } } } } };
        PFSService.postToEndpoint(`workitemupdated`, { req: body });
        setOpenDialog(false);
    };

    const filterByProjectName = async (idList: number[], projectName: string, workItemType: string) => {
        const workItemProjectNameList = await Promise.all(
            idList.map(async (id) => ({
                [id]: await VSSService.getWorkItemProjectName(id)
            }))
        );

        const workItemProjectMap = Object.assign({}, ...workItemProjectNameList);

        const filteredIds = Object.entries(workItemProjectMap)
            .filter(([id, name]) => name === projectName)
            .map(([id]) => Number(id));

        const tunedFilteredIds = await Promise.all(
            filteredIds.map(async (id) => {
                const workItem = await VSSService.getWorkItemById(id);
                return {
                    id,
                    workItemType: workItem.fields['System.WorkItemType']
                };
            })
        );

        const wiTypeFilter = tunedFilteredIds.filter(item => item.workItemType === workItemType)
            .map(item => item.id);
        return wiTypeFilter
    }

    interface WorkItemUpdate {
        id: number;
        rev: number;
        revisedBy: {
            displayName: string;
        };
        revisedDate: string;
        fields: {
            [fieldName: string]: {
                newValue: string | number | boolean;
                oldValue: string | number | boolean;
            };
        };
    }

    interface WorkItemUpdatesResponse {
        value: WorkItemUpdate[];
    }

    const whoLockedAllTypes = useRef<{ whoLocked: string, id: number }[]>([]);
    const whoLocked = async (idList: number[]) => {
        who.current = []
        const { pat } = await PFSService.getEnvironment();
        const auth = Buffer.from(`:${pat}`).toString('base64');
        const responses = idList.map(async (id) => {
            let allUpdates: any[] = [];
            let skip = 0;
            let hasMore = true;
            const pageSize = 100;

            while (hasMore) {
                const uri = `https://dev.azure.com/${organization}/${project}/_apis/wit/workitems/${id}/updates?$top=${pageSize}&$skip=${skip}&api-version=7.1-preview.3`;
                const response = await axios.get<WorkItemUpdatesResponse>(uri, {
                    headers: {
                        'Authorization': `Basic ${auth}`,
                        'Content-Type': 'application/json',
                    },
                })

                if (response.data.value.length > 0) {
                    allUpdates = allUpdates.concat(response.data.value);
                    skip += pageSize;
                } else {
                    hasMore = false;
                }
            }

            const filteredUpdates: any = []
            allUpdates.forEach(update => {
                const isLocked = update.fields?.['Custom.Locke']?.newValue;
                if (isLocked == true) {
                    filteredUpdates.push(update);
                }
            });
            const whoLockedIt = filteredUpdates[filteredUpdates.length - 1].revisedBy.name.replace(/\s*<[^>]*>/g, '');
            const workItemId = filteredUpdates[filteredUpdates.length - 1].workItemId
            whoLockedAllTypes.current.push({ whoLocked: whoLockedIt, id: workItemId });
        });
    }

    const fetchWorkItems = async () => {
        const query = {
            query: `
                SELECT [System.Id], [System.Title] , [Custom.Locke]
                FROM WorkItems
                WHERE [Custom.Locke] = 'true'
                    AND ([System.WorkItemType] = '${beta}'
                    OR [System.WorkItemType] = '${rc}')
                ORDER BY [System.ChangedDate] DESC
            `
        };

        const { pat } = await PFSService.getEnvironment();
        const authHeader = `Basic ${btoa(`PAT:${pat}`)}`;

        try {
            const { data } = await axios.post(url, query, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": authHeader
                }
            });
            const workItems: { id: number, url: string }[] = data.workItems;
            const workItemIds = workItems.map(wi => wi.id)
            if (workItems.length > 0) {
                setLockedIds(workItemIds);
            }
            await whoLocked(workItemIds);
        } catch (error) {
            console.error("Error fetching work items:", error);
        }
    };

    React.useEffect(() => {
        fetchWorkItems();
    }, []);

    const workItem = useRef<any | null>(null);
    const projectName = useRef<string>('');
    const filteredLockedIds = useRef<number[]>([])
    const who = useRef<string[]>([])

    React.useEffect(() => {
        VSSService.on(`staged`, async (data) => {
            workItem.current = data.workItem;
            projectName.current = await VSSService.getWorkItemProjectName(workItem.current?.id);
            const workItemType = workItem.current.fields['System.WorkItemType'];
            filteredLockedIds.current = await filterByProjectName(lockedIds, projectName.current, workItemType);
            who.current = whoLockedAllTypes.current
                .filter(wi => filteredLockedIds.current.includes(wi.id))
                .map(wi => wi.whoLocked);
            if (filteredLockedIds.current.length > 0) {
                setOpenDialog(true);
            }

        }
        );

    }, [lockedIds]);


    if (openDialog == true) {
        return (
            <div>
                <Dialog
                    hidden={!openDialog}
                    onDismiss={() => setOpenDialog(false)}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: `A ${workItem.current.fields['System.WorkItemType']} with ID ${filteredLockedIds.current} is locked for ${projectName.current} by ${who.current}.`,
                        subText: "Would you like to proceed with this deployment? It will overwrite the current locked one."
                    }}
                >
                    <DialogFooter>
                        <PrimaryButton onClick={onConfirmClicked} text="Confirm" />
                        <DefaultButton onClick={onCancelClicked} text="Cancel" />
                    </DialogFooter>
                </Dialog>
            </div >
        );
    }

    return <>hello world</>;

}

export default PFSActionsDeploy;